<script setup lang="ts">
import NavigationTop from "@/src/components/NavigationTop.vue";
import SectionError from "@/src/components/SectionError.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextBody1Skeleton from "@/src/components/TextBody1Skeleton.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import TextHeader3 from "@/src/components/TextHeader3.vue";
import { useUserStore } from "@/src/stores/userStore";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const { t, n } = useI18n();
const route = useRoute();
const router = useRouter();
const userStore = useUserStore();

const { userLoyaltyPoints, fetchLoyaltyPointsFailure } = storeToRefs(userStore);

const handleBack = () => {
  if (route.query && typeof route.query["returnTo"] === "string") {
    return router.push({ path: "/account", query: { returnTo: route.query["returnTo"] } });
  }
  router.push({ path: "/account" });
};

const handleTryAgain = () => {
  window.location.reload();
};
</script>

<template>
  <div class="w-full max-w-[1140px] flex flex-col justify-center items-center relative">
    <TextHeader1 class="p-10">{{ t("account.rewards.label") }}</TextHeader1>
    <NavigationTop @back="handleBack" />
    <div v-if="!fetchLoyaltyPointsFailure" class="p-5">
      <div class="w-full flex flex-col gap-5 border-rewards-info-border border p-5 mb-5">
        <h3 class="flex justify-between">
          <TextHeader3>{{ t("rewards.howItWorks") }}</TextHeader3>
        </h3>
        <TextBody1 class="space-y-5">
          <div>
            {{ t("rewards.descriptionOfPoints") }}
          </div>
          <div>
            {{ t("rewards.descriptionOfRedemption") }}
          </div>
        </TextBody1>
      </div>
      <div class="w-full flex flex-col gap-5 border-rewards-info-border border p-5 mb-5">
        <h3 class="flex justify-between">
          <TextHeader3>{{ t("rewards.balance") }}</TextHeader3>
        </h3>
        <TextBody1 class="flex justify-between">
          <div class="whitespace-nowrap">
            {{ t("rewards.currentBalance") }}:
          </div>
          <div v-if="userLoyaltyPoints !== null">
            {{ t('rewards.points', { balance: userLoyaltyPoints.pointsBalance || 0 }) }}
          </div>
          <TextBody1Skeleton v-else class="pl-5"/>
        </TextBody1>
        <TextBody1 class="flex justify-between">
          <div class="whitespace-nowrap">
            {{ t("rewards.redemptionValue") }}:
          </div>
          <div v-if="userLoyaltyPoints !== null">
            {{  n((userLoyaltyPoints.pointsValueCents || 0) / 100, 'currency')  }}
          </div>
          <TextBody1Skeleton v-else class="pl-5"/>
        </TextBody1>
      </div>
      <div class="w-full flex flex-col gap-5 border-rewards-info-border border p-5">
        <h3 class="flex justify-between">
          <TextHeader3>{{ t("rewards.howToRedeem") }}</TextHeader3>
        </h3>
        <div>
          <TextBody1>
            {{ t("rewards.redemptionInstructions") }}
          </TextBody1>
        </div>
      </div>
    </div>
    <div v-else-if="fetchLoyaltyPointsFailure" class="max-w-[600px]">
      <SectionError :error-message="t('general.pageError.error')" :try-again-handler="handleTryAgain" />  
    </div>
  </div>
</template>
