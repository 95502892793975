<script setup lang="ts">
import Icon from "@/src/components/Icon.vue";
import { faArrowLeftLong, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { DateTime } from "luxon";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

defineProps<{
  minDate: DateTime;
  maxDate: DateTime;
}>();

const model = defineModel<DateTime | null>({ default: null });

const onCalendarUpdated = (newDate: Date) => {
  model.value = DateTime.fromJSDate(newDate).toUTC();
};
</script>

<template>
  <VueDatePicker
    class="th-calendar block w-full"
    :modelValue="model?.toJSDate()"
    @update:modelValue="onCalendarUpdated"
    inline
    auto-apply
    :enable-time-picker="false"
    :min-date="minDate.toJSDate()"
    :max-date="maxDate.toJSDate()"
    :month-change-on-scroll="false"
    :prevent-min-max-navigation="true"
    :week-start="0"
    month-name-format="long"
    :day-names="[
      t('calendar.dayName.sunday'),
      t('calendar.dayName.monday'),
      t('calendar.dayName.tuesday'),
      t('calendar.dayName.wednesday'),
      t('calendar.dayName.thursday'),
      t('calendar.dayName.friday'),
      t('calendar.dayName.saturday'),
    ]">
    <template #arrow-left>
      <Icon :icon="faArrowLeftLong" size="2x" />
    </template>
    <template #arrow-right>
      <Icon :icon="faArrowRightLong" size="2x" />
    </template>
  </VueDatePicker>
</template>

<style>
.th-calendar .dp__theme_light {
  --dp-background-color: #fff;
  --dp-text-color: #000; /* Customised */
  --dp-hover-color: #f3f3f3;
  --dp-hover-text-color: #212121;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: #F1AB9B; /* Customised */
  --dp-primary-disabled-color: #6bacea;
  --dp-primary-text-color: #000; /* Customised */
  --dp-secondary-color: #C8C8C7; /* Customised */
  --dp-border-color: #D1D1D1;
  --dp-menu-border-color: #D1D1D1;
  --dp-border-color-hover: #aaaeb7;
  --dp-border-color-focus: #aaaeb7;
  --dp-disabled-color: #FFF;  /* Customised */
  --dp-scroll-bar-background: #f3f3f3;
  --dp-scroll-bar-color: #959595;
  --dp-success-color: #76d275;
  --dp-success-color-disabled: #a3d9b1;
  --dp-icon-color: #000000;
  --dp-danger-color: #ff6f60;
  --dp-marker-color: #ff6f60;
  --dp-tooltip-color: #fafafa;
  --dp-disabled-color-text: #CACACA; /* Customised */
  --dp-highlight-color: rgb(25 118 210 / 10%);
  --dp-range-between-dates-background-color: var(--dp-hover-color, #f3f3f3);
  --dp-range-between-dates-text-color: var(--dp-hover-text-color, #212121);
  --dp-range-between-border-color: var(--dp-hover-color, #f3f3f3);
}

.th-calendar.dp__main > div {
  width: 100%;
}

/* Hidden tooltip we don't use */
.th-calendar.dp__main > div:first-child {
  display: none;
}

.th-calendar .dp--past {
  background-color: #F6F5F4;
}

.th-calendar .dp__calendar_row {
  display: flex;
}

.th-calendar .dp__calendar_item {
  width: 100%;
}

.th-calendar .dp__cell_inner {
  border: 1px solid #F1F1F0;
  font-weight: normal;
  justify-content: left;
  padding: 10px 0 20px 10px;
  width: 100%;
}

.th-calendar .dp__active_date {
  border-color: #F1AB9B;
}

.th-calendar .dp__cell_offset {
  background-color: #F6F5F4;
}

.th-calendar .dp--header-wrap {
  padding: 1.25rem;
}

.th-calendar .dp__month_year_wrap {
  padding: 0 5px 0 5px;
  gap: 5px;
  justify-content: center;
}

.th-calendar .dp__month_year_select {
  color: #000;
  font-size: 1.6rem;
  font-weight: normal;
  gap: 5px;
  justify-content: normal;
  pointer-events: none;
  text-transform: uppercase;
  width: auto;
}

.th-calendar .dp__month_year_select:first-child {
  justify-content: right;
}

.th-calendar .dp__calendar_header_item {
  color: #000;
  font-size: 1.3rem;
  font-weight: normal;
  padding-top: 5px;
  height: 30px;
  text-transform: uppercase;
  width: 100%;
}

.th-calendar .dp__inner_nav:hover {
  background: none;
  color: #717171
}

.th-calendar .dp__inner_nav_disabled.dp__inner_nav:hover {
  color: var(--dp-disabled-color-text);
}

@media only screen and (width <= 600px) {
  .th-calendar .dp__flex_display {
    flex-direction: inherit;
  }
}

:root {
  /*General*/
  --dp-font-family: "Mallory Book", sans-serif;
  --dp-border-radius: 0; /*Configurable border-radius*/
  --dp-cell-border-radius: 0; /*Specific border radius for the calendar cell*/
  --dp-common-transition: all 0.1s ease-in; /*Generic transition applied on buttons and calendar cells*/

  /*Sizing*/
  --dp-button-height: 35px; /*Size for buttons in overlays*/
  --dp-month-year-row-height: 35px; /*Height of the month-year select row*/
  --dp-month-year-row-button-size: 35px; /*Specific height for the next/previous buttons*/
  --dp-button-icon-height: 16px; /*Icon sizing in buttons*/
  --dp-cell-size: auto; /*Width and height of calendar cell*/
  --dp-cell-padding: 0; /*Padding in the cell*/
  --dp-common-padding: 0; /*Common padding used*/
  --dp-input-icon-padding: 35px; /*Padding on the left side of the input if icon is present*/
  --dp-input-padding: 6px 30px 6px 12px; /*Padding in the input*/
  --dp-menu-width: 600px; /*Adjust the min width of the menu*/
  --dp-menu-min-width: 300px; /*Adjust the min width of the menu*/
  --dp-action-buttons-padding: 2px 5px; /*Adjust padding for the action buttons in action row*/
  --dp-row-margin:  0 0; /*Adjust the spacing between rows in the calendar*/
  --dp-calendar-header-cell-padding: 0.5rem; /*Adjust padding in calendar header cells*/
  --dp-two-calendars-spacing: 10px; /*Space between multiple calendars*/
  --dp-overlay-col-padding: 3px; /*Padding in the overlay column*/
  --dp-time-inc-dec-button-size: 32px; /*Sizing for arrow buttons in the time picker*/
  --dp-menu-padding: 0; /*Menu padding*/

  /*Font sizes*/
  --dp-font-size: 1.6rem; /*Default font-size*/

  /*Transitions*/
  --dp-animation-duration: 0.1s; /*Transition duration*/
  --dp-menu-appear-transition-timing: cubic-bezier(.4, 0, 1, 1); /*Timing on menu appear animation*/
  --dp-transition-timing: ease-out; /*Timing on slide animations*/
}
</style>
