<script setup lang="ts">
import ButtonPrimary from "@/src/components/ButtonPrimary.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextBody3 from "@/src/components/TextBody3.vue";
import TextBodyError from "@/src/components/TextBodyError.vue";
import { useAuthStore } from "@/src/stores/authStore";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

defineProps<{
  errorMessage: string;
  tryAgainHandler?: () => void;
}>();

const authStore = useAuthStore();
const { browserId } = storeToRefs(authStore);
</script>

<template>
  <div class="flex flex-col gap-5 p-10">
    <TextBodyError>
      <TextBody1>
        {{ errorMessage }}
        {{ tryAgainHandler ? t('general.pageError.tryAgainBelow') : t('general.pageError.tryAgainLater') }}
      </TextBody1>
    </TextBodyError>
    <TextBodyError>
      <TextBody3>
        {{ t('general.pageError.errorCode', { browserId }) }}
      </TextBody3>
    </TextBodyError>
    <ButtonPrimary v-if="tryAgainHandler" @click.once="tryAgainHandler">{{ t("general.button.tryAgain") }}</ButtonPrimary>
    <TextBodyError>
      <TextBody1>
        {{ t('general.pageError.contactSupport') }}
      </TextBody1>
    </TextBodyError>
  </div>
</template>
