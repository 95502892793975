
<script setup lang="ts">
import GuestServiceSummary from "@/src/components/GuestServiceSummary.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextHeader3 from "@/src/components/TextHeader3.vue";
import TextLink from "@/src/components/TextLink.vue";
import type { BookingProgressStage } from "@/src/config/stages";
import type {
  LocationAddressOne,
  LocationAddressTwo,
  LocationCity,
  LocationName,
  LocationPostalCode,
  LocationState,
} from "@/src/lib/townhouseApiClient";
import type { ServiceSummary } from "@/src/stores/bookingStore";
import type { DateTime } from "luxon";
import { useI18n } from "vue-i18n";

defineProps<{
  locationName: LocationName;
  addressOne: LocationAddressOne;
  addressTwo?: LocationAddressTwo;
  city: LocationCity;
  state?: LocationState;
  postalCode: LocationPostalCode;
  services: ServiceSummary[][];
  bookingDateTimeUtc: DateTime;
  editMode?: boolean;
  hideServices?: boolean;
}>();

defineEmits<{
  stageEditClicked: [stage: BookingProgressStage];
}>();

const { t } = useI18n();
</script>

<template>
  <div>
    <div class="mb-10">
      <span class="flex justify-between items-center">
        <TextHeader3>{{ t("bookingSummary.headers.dateTime") }}</TextHeader3>
        <TextLink v-if="editMode" @click="$emit('stageEditClicked', 'dateTime')">
          <TextBody1>{{ t("bookingSummary.headers.edit") }}</TextBody1>
        </TextLink>
      </span>
      <p>
        <TextBody1>{{ bookingDateTimeUtc.toLocal().toFormat("T, EEEE, d MMMM y") }}</TextBody1>
      </p>
    </div>
    <div class="mb-10">
      <span class="flex justify-between items-center">
        <TextHeader3>{{ t("bookingSummary.headers.location") }}</TextHeader3>
        <TextLink v-if="editMode" @click="$emit('stageEditClicked', 'location')">
          <TextBody1>{{ t("bookingSummary.headers.edit") }}</TextBody1>
        </TextLink>
      </span>
      <p>
        <TextBody1>{{ t("general.townhouse") }} {{ locationName }}</TextBody1>
      </p>
      <p>
        <TextBody1>{{ addressOne }}, {{ addressTwo ? `${addressTwo}, ` : "" }}{{ city }}, {{ state ? `${state}, ` : "" }}{{ postalCode }}</TextBody1>
      </p>
    </div>
    <div :class="{ 'mb-10': !hideServices }">
      <span class="flex justify-between items-center">
        <TextHeader3>{{ t("bookingSummary.headers.guests") }}</TextHeader3>
        <TextLink v-if="editMode" @click="$emit('stageEditClicked', 'guests')">
          <TextBody1>{{ t("bookingSummary.headers.edit") }}</TextBody1>
        </TextLink>
      </span>
      <p>
        <TextBody1>{{ services.length === 1 ? t('bookingSummary.guests.one') : services.length }}</TextBody1>
      </p>
    </div>
    <div v-if="!hideServices">
      <span class="flex justify-between items-center">
        <TextHeader3>{{ t("bookingSummary.headers.services") }}</TextHeader3>
        <TextLink v-if="editMode" @click="$emit('stageEditClicked', 'services')">
          <TextBody1>{{ t("bookingSummary.headers.edit") }}</TextBody1>
        </TextLink>
      </span>
      <GuestServiceSummary :services="services"></GuestServiceSummary>
    </div>
  </div>
</template>
