<script setup lang="ts">
import BookingSummary from "@/src/components/BookingSummary.vue";
import ButtonInScreenPrimary from "@/src/components/ButtonInScreenPrimary.vue";
import ButtonInScreenTertiary from "@/src/components/ButtonInScreenTertiary.vue";
import Checkbox from "@/src/components/Checkbox.vue";
import Icon from "@/src/components/Icon.vue";
import SectionDivider from "@/src/components/SectionDivider.vue";
import SectionError from "@/src/components/SectionError.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextBody3 from "@/src/components/TextBody3.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import TextHeader3 from "@/src/components/TextHeader3.vue";
import TextLink from "@/src/components/TextLink.vue";
import type { BookingProgressStage } from "@/src/config/stages";
import type { LocationId, PaymentAccount } from "@/src/lib/townhouseApiClient";
import PopupAddPaymentAccount from "@/src/popups/PopupAddPaymentAccount.vue";
import PopupDidYouKnow from "@/src/popups/PopupDidYouKnow.vue";
import PopupMedicalOrAccessNotes from "@/src/popups/PopupMedicalOrAccessNotes.vue";
import PopupSelectPaymentAccount from "@/src/popups/PopupSelectPaymentAccount.vue";
import { useAuthStore } from "@/src/stores/authStore";
import { useBookingStore } from "@/src/stores/bookingStore";
import { faCcAmex, faCcMastercard, faCcVisa } from "@fortawesome/free-brands-svg-icons";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import { faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import { type IconDefinition, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { storeToRefs } from "pinia";
import { computed, onMounted, onUnmounted, ref, useTemplateRef, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const { t, n } = useI18n();
const bookingStore = useBookingStore();
const authStore = useAuthStore();
const router = useRouter();

const props = defineProps<{
  locationId: LocationId;
  press: boolean | undefined;
}>();

const {
  expiryDateTimeUtc,
  paymentAccountChosen,
  termsAndConditionsAccepted,
  medicalOrAccessNotes,
  didYouKnowPopupVisible,
  confirmBookingFailure,
} = storeToRefs(bookingStore);

// FIXME: Hidden for SC testing, put back when it is ready and working
// const promoCode = ref<string | null>(null);
const expiresIn = ref<string>();
const selectingPaymentAccount = ref(false);
const addingPaymentAccount = ref(false);
const addPaymentAccountError = ref<string | null>(null);
const showMedicalOrAccessNotesPopup = ref(false);

const pageHeader = useTemplateRef("pageHeader");

const bookingSummary = computed(() => {
  return bookingStore.createBookingSummary();
});

const bookingTotalPriceCents = computed(() => {
  return bookingStore.calculateTotalBookingPriceCents();
});

const handleMedicalOrAccessNotesCancelled = () => {
  showMedicalOrAccessNotesPopup.value = false;
};

const handleMedicalOrAccessNotesSave = (medicalOrAccessNotes: string | null) => {
  bookingStore.setMedicalOrAccessNotesInfo(medicalOrAccessNotes);
};

const handleSelectPaymentAccount = () => {
  selectingPaymentAccount.value = true;

  authStore.analytics.track("Select A Payment Account Shown", {});
};

const handlePaymentAccountAddClickedInSelectPopup = () => {
  selectingPaymentAccount.value = false;
  addingPaymentAccount.value = true;
  bookingStore.startCreatePaymentAccount();
};

const handleAddPaymentAccountStart = () => {
  addingPaymentAccount.value = true;
  bookingStore.startCreatePaymentAccount();
};

const handleAddPaymentAccountCancelled = () => {
  addingPaymentAccount.value = false;
};

const handleAddPaymentAccountSuccess = async () => {
  addingPaymentAccount.value = false;
  await bookingStore.refetchPaymentAccounts();
};

const handleAddPaymentAccountFailed = (reason: string) => {
  addPaymentAccountError.value = reason;
  // FIXME: Do something with it
};

const updateExpiresIn = () => {
  if (!expiryDateTimeUtc.value) {
    return;
  }

  const durationTillExpiry = expiryDateTimeUtc.value.diffNow(["minutes", "seconds", "milliseconds"]);

  if (durationTillExpiry.minutes <= 0 && durationTillExpiry.seconds <= 0) {
    authStore.analytics.track("Booking Expired", {
      expiredAtDateTimeUtc: expiryDateTimeUtc.value.toString(),
    });

    bookingStore.setReservationExpired(true);
    bookingStore.clearBookingDateTimeUtc();

    return router.push({
      path: `/locations/${props.locationId}/date-time`,
    });
  }

  expiresIn.value = `${durationTillExpiry.minutes}:${durationTillExpiry.seconds.toString().padStart(2, "0")}`;
};

watch(confirmBookingFailure, (newConfirmBookingFailure) => {
  if (newConfirmBookingFailure) {
    pageHeader.value?.scrollIntoView({ behavior: "smooth" });
  }
});

watch(termsAndConditionsAccepted, (newValue) => {
  authStore.analytics.track(newValue ? "Terms and Conditions Accepted" : "Terms and Conditions Unaccepted", {});
});

onMounted(() => {
  // Initialize the countdown
  updateExpiresIn();

  // Set up an interval to update the remaining time every second
  const interval = setInterval(updateExpiresIn, 1000);

  // Clear the interval when the component is unmounted
  onUnmounted(() => {
    clearInterval(interval);
  });
});

const handleStageEditClicked = (stage: BookingProgressStage) => {
  switch (stage) {
    case "location":
      return router.push({
        path: "/",
      });
    case "guests":
      return router.push({
        path: `/locations/${bookingStore.locationId}`,
      });
    case "services": {
      return router.push({
        path: `/locations/${bookingStore.locationId}/guests`,
      });
    }
    case "dateTime":
      return router.push({
        path: `/locations/${bookingStore.locationId}/date-time`,
      });
    default:
      return;
  }
};

const paymentCardIconByPaymentAccountCardType = (cardType: PaymentAccount["cardType"]): IconDefinition => {
  switch (cardType) {
    case "amex":
      return faCcAmex;
    case "visa":
      return faCcVisa;
    case "mastercard":
      return faCcMastercard;
    case "other":
      return faCreditCard;
  }
};
</script>

<template>
  <PopupMedicalOrAccessNotes v-if="showMedicalOrAccessNotesPopup"
    :text="medicalOrAccessNotes"
    @cancelled="handleMedicalOrAccessNotesCancelled"
    @save="handleMedicalOrAccessNotesSave"/>
  <PopupSelectPaymentAccount v-if="selectingPaymentAccount"
    @cancelled="selectingPaymentAccount = false"
    @paymentAccountChosenNextClick="selectingPaymentAccount = false"
    @paymentAccountAddClicked="handlePaymentAccountAddClickedInSelectPopup" />
  <PopupAddPaymentAccount v-if="addingPaymentAccount"
    @cancelled="handleAddPaymentAccountCancelled"
    @success="handleAddPaymentAccountSuccess"
    @failed="handleAddPaymentAccountFailed" />
  <PopupDidYouKnow v-if="didYouKnowPopupVisible" variant="one"/>
  <h2 class="flex justify-center text-center my-10" ref="pageHeader">
    <TextHeader1 class="text-center">{{ t('confirm.header') }}</TextHeader1>
  </h2>
  <SectionDivider />
  <div class="p-5 flex justify-center sticky top-0">
    <div class="text-warning border p-5 border-confirm-expiry-border bg-white">
      <TextBody1>
        {{ t('confirm.expiresIn') }}
        <!-- We can't use a monospace font but we don't want the layout to jump around, so fix-width the chars to prevent movement -->
        <span v-for="part in expiresIn?.split('')" class="inline-block text-center" :class="{
          'w-4': part !== ':',
        }">{{ part }}</span>
      </TextBody1>
    </div>
  </div>
  <SectionError v-if="confirmBookingFailure" :error-message="t('confirm.confirmBookingError')"/>
  <div class="m-5">
    <BookingSummary
      @stage-edit-clicked="handleStageEditClicked"
      edit-mode
      :location-name="bookingSummary.locationName"
      :address-one="bookingSummary.addressOne"
      :address-two="bookingSummary.addressTwo || undefined"
      :city="bookingSummary.city"
      :state="bookingSummary.state || undefined"
      :postal-code="bookingSummary.postalCode"
      :services="bookingSummary.serviceSummariesByGuest"
      :booking-date-time-utc="bookingSummary.bookingDateTimeUtc"
    />
  </div>
  <SectionDivider />
  <div class="mx-5 my-10">
    <!-- FIXME: Hidden for SC testing, put back when it is ready and working -->
    <!-- <div class="flex justify-between items-end mb-10">
      <InputText class="w-full max-w-[600px] mr-20" :model-value="promoCode" :label="t('confirm.promo.inputLabel')" />
      <div class="w-[155px]">
        <ButtonInScreenSecondary class="border-2 border-button-in-screen-secondary">{{ t('confirm.promo.apply') }}</ButtonInScreenSecondary>
      </div>
    </div> -->
    <TextHeader3>{{ t("confirm.total.header") }}</TextHeader3>
    <div class="flex justify-between items-center mb-10">
      <TextBody1>{{ t("confirm.total.label") }}</TextBody1>
      <TextBody1>{{ n(bookingTotalPriceCents / 100, 'currency') }}</TextBody1>
    </div>
  </div>
  <SectionDivider />
  <div class="flex justify-center mx-5 my-10">
    <ButtonInScreenTertiary @click="showMedicalOrAccessNotesPopup = !showMedicalOrAccessNotesPopup" class="max-w-[600px]">
      <div class="flex justify-between items-center">
        <div>
          <Icon class="mr-5" :icon="faSquarePlus" size="lg" />
          <TextBody1>{{ t("confirm.medicalInfo") }}</TextBody1>
        </div>
        <Icon :icon="faAngleRight" size="lg" />
      </div>
    </ButtonInScreenTertiary>
  </div>
  <SectionDivider />
  <div class="flex flex-col justify-center mx-5 my-10">
    <TextHeader3 class="block">{{ t("confirm.payment.header") }}</TextHeader3>
    <TextBody3 class="block mb-5">{{ t("confirm.payment.label") }}</TextBody3>
    <ButtonInScreenPrimary v-if="paymentAccountChosen" class="mx-auto max-w-[600px]">
      <div class="flex justify-between items-center">
        <div>
          <Icon class="mr-5" :icon="paymentCardIconByPaymentAccountCardType(paymentAccountChosen.cardType)" size="lg"/>
          <TextBody1>{{ t("confirm.payment.card") }} {{ paymentAccountChosen.lastFour }}</TextBody1>
        </div>
        <TextLink @click="handleSelectPaymentAccount">
          <TextBody1>{{ t("confirm.payment.change") }}</TextBody1>
        </TextLink>
      </div>
    </ButtonInScreenPrimary>
    <ButtonInScreenPrimary v-else class="mx-auto max-w-[600px]" @click="handleAddPaymentAccountStart">
      <div class="flex justify-between items-center">
        <div>
          <Icon class="mr-5" :icon="faCreditCard" size="lg"/>
          <TextBody1>{{ t("confirm.payment.addCard") }}</TextBody1>
        </div>
        <Icon :icon="faAngleRight" size="lg"/>
      </div>
    </ButtonInScreenPrimary>
  </div>
  <SectionDivider />
  <div class="flex items-start mx-5 my-10">
    <Checkbox v-model="termsAndConditionsAccepted" :has-error="false">
      <TextBody3>
        <i18n-t keypath="confirm.terms.mainText">
          <TextLink>
            <a :href="`${t('general.termsAndConditions')}`" target="_blank" @click="() => authStore.analytics.track('Terms and Conditions Link Clicked', {})">{{ t("confirm.terms.linkText") }}</a>
          </TextLink>
        </i18n-t>
      </TextBody3>
    </Checkbox>
  </div>
</template>
