<script setup lang="ts">
import TextBodyError from "@/src/components/TextBodyError.vue";
import TextHeader3 from "@/src/components/TextHeader3.vue";
import { useI18n } from "vue-i18n";

defineProps<{
  label: string;
  rows: number;
  error?: string;
  required?: boolean;
}>();

const { t } = useI18n();
const model = defineModel<string | null>();
</script>

<template>
  <div class="w-full">
    <label>
      <TextHeader3 class="block">
        {{ label }}{{ !required ? ` ${t("input.optionalLabel")}` : "" }}
      </TextHeader3>
      <textarea 
      :class="`w-full rounded-none border-2 ${ error ? 'border-error' : 'border-input-border' } text-base p-5 focus:outline-none focus:border-2 focus:border-input-focus-outline resize-none`"
      v-model="model"
      :aria-required="required"
      :rows="rows.toString()"
      :aria-invalid="Boolean(error)"
      :aria-errormessage="error"/>
    </label>
    <TextBodyError v-if="error" class="block mt-1">
      {{ error }}
    </TextBodyError>
  </div>
</template>
