import type {
  AddOnId,
  BookingReservationId,
  LocationId,
  RemovalId,
  ServiceId,
  UserId,
  ZenotiEmployeeId,
} from "@/src/lib/townhouseApiClient";
import mixpanel from "mixpanel-browser";

type MixpanelTrackEvent =
  | "Account Create Shown"
  | "Account Created"
  | "Account Creation Failed"
  | "Add Payment Account Shown"
  | "Add Payment Card Shown"
  | "Add-On Added"
  | "Add-On Removed"
  | "All Services Removed From All Guests"
  | "All Services Removed From Guest"
  | "Any Employee Chosen"
  | "Booking Confirmed"
  | "Booking Expired"
  | "Booking Reserved"
  | "Booking Reservation Expired"
  | "Create Account Shown"
  | "Date Chosen"
  | "Employee Select Shown"
  | "Group Package Added"
  | "Group Package Removed"
  | "Location Selected"
  | "Log In Failed"
  | "Log In Shown"
  | "Log In Success"
  | "Logged Out"
  | "Medical or Access Needs Changed"
  | "Medical or Access Needs Shown"
  | "Needs Removal Set"
  | "Number of Guests Selected"
  | "Parallel Service Copied To All Guests"
  | "Parallel Services Added"
  | "Parallel Services Removed"
  | "Password Reset Completed"
  | "Password Reset Failed"
  | "Password Reset Started"
  | "Payment Account Added Successfully"
  | "Payment Account Addition Failed"
  | "Payment Account Deleted Successfully"
  | "Payment Card Added Successfully"
  | "Payment Card Addition Failed"
  | "Payment Card Changed"
  | "Payment Card Delete Failed"
  | "Payment Card Deleted Successfully"
  | "Removal Added"
  | "Removal Cleared"
  | "Removal Removed"
  | "Reset Password Completed"
  | "Reset Password Failed"
  | "Reset Password Shown"
  | "Reset Password Started"
  | "Select A Payment Account Shown"
  | "Select A Payment Card Shown"
  | "Service Added"
  | "Service Copied To All Guests"
  | "Service Removed"
  | "Specific Employee Chosen"
  | "Terms and Conditions Accepted"
  | "Terms and Conditions Link Clicked"
  | "Terms and Conditions Unaccepted"
  | "Time Chosen";

type MixpanelTrackPropertiesByEvent = {
  "Account Create Shown": Record<string, never>;
  "Account Created": Record<string, never>;
  "Account Creation Failed": Record<string, never>;
  "Add Payment Account Shown": Record<string, never>;
  "Add Payment Card Shown": Record<string, never>;
  "Add-On Added": {
    guestNumber: number;
    serviceId: ServiceId;
    addOnId: AddOnId;
    isPrBooking: boolean;
    locationId: LocationId;
  };
  "Add-On Removed": {
    guestNumber: number;
    serviceId: ServiceId;
    addOnId: AddOnId;
    isPrBooking: boolean;
    locationId: LocationId;
  };
  "All Services Removed From All Guests": {
    isPrBooking: boolean;
    locationId: LocationId;
  };
  "All Services Removed From Guest": {
    guestNumber: number;
    isPrBooking: boolean;
    locationId: LocationId;
  };
  "Any Employee Chosen": {
    numberOfGuests: number;
    isPrBooking: boolean;
    locationId: LocationId;
  };
  "Booking Confirmed": {
    locationId: LocationId;
    dateTimeUtc: string | null;
  };
  "Booking Expired": {
    expiredAtDateTimeUtc: string;
  };
  "Booking Reserved": {
    locationId: LocationId;
    bookingReservationId: BookingReservationId;
    dateTimeUtc: string;
  };
  "Booking Reservation Expired": {
    expiredAtDateTimeUtc: string;
  };
  "Create Account Shown": Record<string, never>;
  "Date Chosen": {
    dateUtc: string;
  };
  "Employee Select Shown": {
    numberOfGuests: number;
    isPrBooking: boolean;
    locationId: LocationId;
  };
  "Group Package Added": {
    serviceId: ServiceId;
    numberOfGuests: number;
    isPrBooking: boolean;
    locationId: LocationId;
  };
  "Group Package Removed": {
    serviceId: ServiceId;
    numberOfGuests: number;
    isPrBooking: boolean;
    locationId: LocationId;
  };
  "Location Selected": {
    locationId: LocationId;
    isPrBooking: boolean;
  };
  "Log In Failed": Record<string, never>;
  "Log In Shown": Record<string, never>;
  "Log In Success": Record<string, never>;
  "Logged Out": Record<string, never>;
  "Medical or Access Needs Changed": Record<string, never>;
  "Medical or Access Needs Shown": Record<string, never>;
  "Needs Removal Set": {
    guestNumber: number;
    serviceId: ServiceId;
    needsRemoval: boolean;
    isPrBooking: boolean;
    locationId: LocationId;
  };
  "Number of Guests Selected": {
    numberOfGuests: number;
    isPrBooking: boolean;
    locationId: LocationId;
  };
  "Parallel Service Copied To All Guests": {
    numberOfGuests: number;
    serviceOneId: ServiceId;
    serviceOneAddOnIds: AddOnId[];
    serviceOneRemovalIds: RemovalId[];
    serviceTwoId: ServiceId;
    serviceTwoAddOnIds: AddOnId[];
    serviceTwoRemovalIds: RemovalId[];
    isPrBooking: boolean;
    locationId: LocationId;
  };
  "Parallel Services Added": {
    guestNumber: number;
    serviceOneId: ServiceId;
    serviceTwoId: ServiceId;
    isPrBooking: boolean;
    locationId: LocationId;
  };
  "Parallel Services Removed": {
    guestNumber: number;
    serviceOneId: ServiceId;
    serviceTwoId: ServiceId;
    isPrBooking: boolean;
    locationId: LocationId;
  };
  "Password Reset Completed": Record<string, never>;
  "Password Reset Failed": Record<string, never>;
  "Password Reset Started": Record<string, never>;
  "Payment Account Added Successfully": Record<string, never>;
  "Payment Account Addition Failed": Record<string, never>;
  "Payment Account Deleted Successfully": Record<string, never>;
  "Payment Card Added Successfully": Record<string, never>;
  "Payment Card Addition Failed": Record<string, never>;
  "Payment Card Changed": Record<string, never>;
  "Payment Card Delete Failed": Record<string, never>;
  "Payment Card Deleted Successfully": Record<string, never>;
  "Removal Added": {
    guestNumber: number;
    serviceId: ServiceId;
    removalId: RemovalId;
    isPrBooking: boolean;
    locationId: LocationId;
  };
  "Removal Cleared": {
    guestNumber: number;
    serviceId: ServiceId;
    isPrBooking: boolean;
    locationId: LocationId;
  };
  "Removal Removed": {
    guestNumber: number;
    serviceId: ServiceId;
    removalId: RemovalId;
    isPrBooking: boolean;
    locationId: LocationId;
  };
  "Reset Password Completed": Record<string, never>;
  "Reset Password Failed": Record<string, never>;
  "Reset Password Shown": Record<string, never>;
  "Reset Password Started": Record<string, never>;
  "Select A Payment Account Shown": Record<string, never>;
  "Select A Payment Card Shown": Record<string, never>;
  "Service Added": {
    guestNumber: number;
    serviceId: ServiceId;
    isPrBooking: boolean;
    locationId: LocationId;
  };
  "Service Copied To All Guests": {
    numberOfGuests: number;
    serviceId: ServiceId;
    addOnIds: AddOnId[];
    removalIds: RemovalId[];
    isPrBooking: boolean;
    locationId: LocationId;
  };
  "Service Removed": {
    guestNumber: number;
    serviceId: ServiceId;
    isPrBooking: boolean;
    locationId: LocationId;
  };
  "Specific Employee Chosen": {
    numberOfGuests: number;
    isPrBooking: boolean;
    locationId: LocationId;
    employeeId: ZenotiEmployeeId;
  };
  "Terms and Conditions Accepted": Record<string, never>;
  "Terms and Conditions Link Clicked": Record<string, never>;
  "Terms and Conditions Unaccepted": Record<string, never>;
  "Time Chosen": {
    dateTimeUtc: string;
  };
};

export class MixpanelClient {
  constructor(projectToken: string) {
    mixpanel.init(projectToken, {
      debug: false,
      // biome-ignore lint/style/useNamingConvention: Mixpanel naming convention
      track_pageview: true,
      persistence: "localStorage",
    });
  }

  track<T extends MixpanelTrackEvent>(eventName: T, properties: MixpanelTrackPropertiesByEvent[T]) {
    mixpanel.track(eventName, properties);
  }

  identify(userId: UserId) {
    mixpanel.identify(userId);
  }

  reset() {
    mixpanel.reset();
  }
}
