<script setup lang="ts">
import TextHeader1 from "@/src/components/TextHeader1.vue";
import { envNameFromOrigin, storeLocatorUidFromOrigin } from "@/src/config/env";
import { onMounted } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

defineProps<{
  press: boolean | undefined;
}>();

onMounted(() => {
  // Filter the list of shown locations down to just what we want to be visible in staging and development
  // @ts-expect-error
  window.storeLocatorFilterLocationsHandler = (locations: { data: Record<string, string | number> }[]) => {
    const currentEnv = envNameFromOrigin();
    if (currentEnv === "gb-staging" || currentEnv === "development") {
      return locations.filter((location) => Object.values(location.data).includes("GB-LON-GPS"));
    }

    return locations;
  };

  // Re-route the book now link to this booking flow when a map marker is clicked
  // @ts-expect-error
  window.storeLocatorShowMapPopupInfoEvent = (
    _store: { data: Record<string, string | number> },
    popup: { _content: HTMLElement },
  ) => {
    const locationId = popup._content.querySelector(".storelocator-CENTERCODE")?.textContent || null;
    const bookNowButton = popup._content.querySelector(".storelocator-THBOOKNOW .storelocator-btn");

    if (!(bookNowButton && locationId)) {
      return;
    }

    const currentQuery = new URLSearchParams(window.location.search).toString();
    bookNowButton.setAttribute("href", `/locations/${locationId}${currentQuery ? `?${currentQuery}` : ""}`);
  };

  // Re-route the book now link under every location shown in the locations list
  // @ts-expect-error
  window.storeLocatorListLocationsHandler = () => {
    const locations = document.querySelectorAll(".storelocator-store");
    const currentQuery = new URLSearchParams(window.location.search).toString();

    for (const location of Array.from(locations)) {
      const locationId = location.querySelector(".storelocator-CENTERCODE")?.textContent || null;
      const bookNowButton = location.querySelector(".storelocator-THBOOKNOW .storelocator-btn");

      if (!(bookNowButton && locationId)) {
        continue;
      }

      bookNowButton.setAttribute("href", `/locations/${locationId}${currentQuery ? `?${currentQuery}` : ""}`);
    }
  };
});
</script>

<template>
  <h2 class="flex justify-center my-5">
    <TextHeader1 class="text-center">{{ t('locations.header') }}</TextHeader1>
  </h2>
  <div class="px-5 storelocatorwidget">
    <div id="storelocatorwidget"></div>
  </div>
  <component
    is="script"
    type="text/javascript"
    id="mapboxscript"
    src="https://cdn.storelocatorwidgets.com/widget/mapbox-gl.js"
  ></component>
  <component
    is="script"
    type="text/javascript"
    id="storelocatorscript"
    :data-uid="storeLocatorUidFromOrigin()"
    data-platform="MapTiler"
    src="https://cdn.storelocatorwidgets.com/widget/widget.js"
  ></component>
</template>
