<script setup lang="ts">
import Icon from "@/src/components/Icon.vue";
import TextBodyError from "@/src/components/TextBodyError.vue";
import TextHeader3 from "@/src/components/TextHeader3.vue";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

defineProps<{
  label: string;
  autocomplete: "new-password" | "current-password";
  error?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
}>();
const model = defineModel();

const hidePassword = ref(true);
</script>

<template>
  <div>
    <label :class="`${disabled ? 'text-input-disabled' : ''}`">
      <TextHeader3>
        {{ label }}{{ !required ? ` ${t("input.optionalLabel")}` : '' }}
      </TextHeader3>
      <div class="relative w-full">
        <input
        :class="`w-full rounded-none border-2 ${ error ? 'border-error' : 'border-input-border' } text-base p-5 focus:outline-hidden focus:border-2 focus:border-input-focus-outline ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`"
        v-model="model"
        :type="hidePassword ? 'password' : 'type'"
        :autocomplete="autocomplete"
        spellcheck="false"
        :aria-required="required"
        autocapitalize="none"
        :placeholder="placeholder"
        :disabled="disabled"
        :aria-invalid="Boolean(error)"
        :aria-errormessage="error"
        />
        <button v-on:click="hidePassword = !hidePassword" type="button" :class="`absolute inset-y-0 right-5 text-center ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`"
          aria-controls="passwordInput"
          :aria-expanded="!hidePassword"
          :disabled="disabled"
          >
          <Icon v-if="!hidePassword" :icon="faEye" size="2xl" class="text-input-icon px-[1.25px]"/>
          <Icon v-if="hidePassword" :icon="faEyeSlash" size="2xl" class="text-input-icon"/>
        </button>
      </div>
    </label>
    <TextBodyError v-if="error" class="block mt-1">
      {{ error }}
    </TextBodyError>
  </div>
</template>
