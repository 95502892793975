<script setup lang="ts">
import Icon from "@/src/components/Icon.vue";
import TextHeader2 from "@/src/components/TextHeader2.vue";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

const { valueOne, valueTwo, submitMode, disabled } = defineProps<{
  labelOne: string;
  valueOne: boolean;
  labelTwo: string;
  valueTwo: boolean;
  submitMode?: boolean;
  disabled?: boolean;
}>();

const model = defineModel<typeof valueOne | typeof valueTwo | null>();

const handleClickOne = () => {
  if (disabled || (submitMode && model.value === valueTwo)) {
    return;
  }

  model.value = valueOne;
};

const handleClickTwo = () => {
  if (disabled || (submitMode && model.value === valueOne)) {
    return;
  }

  model.value = valueTwo;
};
</script>

<template>
  <div class="flex gap-5" role="radiogroup">
    <button
      :class="`block w-full p-5 aspect-5/4 ${model === valueOne ? 'bg-button-binary-selected' : 'bg-button-binary-unselected'} ${disabled || (submitMode && model === valueTwo) ? 'bg-button-disabled cursor-not-allowed' : ''}`"
      @click.stop="handleClickOne"
      role="radio"
      :aria-checked="model === valueOne"
      :disabled="disabled || (submitMode && model === valueTwo)"
    >
      <TextHeader2 v-if="!submitMode || (submitMode && model !== valueOne)" :class="disabled || (submitMode && model === valueTwo) ? 'text-button-text-disabled' : ''">
        {{ labelOne }}
      </TextHeader2>
      <Icon v-else-if="submitMode && model === valueOne" :icon="faCircleNotch" size="2x" class="animate-spin"/>
    </button>
    <button
      @click.stop="handleClickTwo"
      :class="`block w-full p-5 py-10 ${!submitMode && model === valueTwo ? 'bg-button-binary-selected' : 'bg-button-binary-unselected'} ${disabled || (submitMode && model === valueOne) ? 'bg-button-disabled cursor-not-allowed' : ''}`"
      role="radio"
      :aria-checked="model === valueTwo"
      :disabled="disabled || (submitMode && model === valueOne)"
    >
      <TextHeader2 v-if="!submitMode || (submitMode && model !== valueTwo)" :class="disabled || (submitMode && model === valueOne) ? 'text-button-text-disabled' : ''">
        {{ labelTwo }}
      </TextHeader2>
      <Icon v-else-if="submitMode && model === valueTwo" :icon="faCircleNotch" size="2x" class="animate-spin"/>
    </button>
  </div>
</template>
