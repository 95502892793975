export type EnvName = "gb-production" | "us-production" | "gb-staging" | "us-staging" | "development";
export type Region = "GB" | "US";
export type Currency = "GBP" | "USD";

export const envNameFromOrigin = (): EnvName => {
  switch (location.origin) {
    case "https://booking.townhouse.co.uk":
      return "gb-production";
    case "https://booking.townhousebeauty.com":
      return "us-production";
    case "https://booking-new.townhouse.co.uk":
      return "gb-production";
    case "https://booking-new.townhousebeauty.com":
      return "us-production";
    case "https://booking.staging.townhouse.co.uk":
      return "gb-staging";
    case "https://booking.staging.townhousebeauty.com":
      return "us-staging";
    default: {
      if (location.origin.endsWith("townhouse.vercel.app")) {
        return "gb-staging";
      }

      return "development";
    }
  }
};

export const regionFromOrigin = (): Region => {
  const envName = envNameFromOrigin();

  switch (envName) {
    case "gb-production":
      return "GB";
    case "us-production":
      return "US";
    case "gb-staging":
      return "GB";
    case "us-staging":
      return "US";
    case "development":
      return "GB";
  }
};

export const apiOriginFromOrigin = (): string => {
  switch (envNameFromOrigin()) {
    case "gb-production":
      return import.meta.env.VITE_TOWNHOUSE_API_ORIGIN_GB_PRODUCTION;
    case "us-production":
      return import.meta.env.VITE_TOWNHOUSE_API_ORIGIN_US_PRODUCTION;
    case "gb-staging":
      return import.meta.env.VITE_TOWNHOUSE_API_ORIGIN_GB_STAGING;
    case "us-staging":
      return import.meta.env.VITE_TOWNHOUSE_API_ORIGIN_US_STAGING;
    default:
      return import.meta.env.VITE_TOWNHOUSE_API_ORIGIN;
  }
};

export const currencyFromOrigin = (): Currency => {
  const envName = envNameFromOrigin();

  switch (envName) {
    case "gb-production":
      return "GBP";
    case "us-production":
      return "USD";
    case "gb-staging":
      return "GBP";
    case "us-staging":
      return "USD";
    case "development":
      return "GBP";
  }
};

export const storeLocatorUidFromOrigin = (): string => {
  const envName = envNameFromOrigin();

  switch (envName) {
    case "gb-production":
      return "MI29awMefbtCj9aGNuGhZMNjCECQ4kOK";
    case "us-production":
      return "CeIYLhSnTfWMKf8s4lHFEkXR619FlMBD";
    case "gb-staging":
      return "1607da525f961b5c78c050e22338ed9a";
    case "us-staging":
      return "d9080a6ad8ac6086375de117fd193f75";
    case "development":
      return "1607da525f961b5c78c050e22338ed9a";
  }
};

export const mixpanelTokenFromOrigin = (): string => {
  const envName = envNameFromOrigin();

  const mixpanelTokenMap: Record<EnvName, string> = {
    "gb-staging": "fd3643a73f6cd0a2273043e98100247e",
    "gb-production": "ea4b938fa0ce276341162d18c4af4367",
    "us-staging": "3995bf111ad1f6a2da83c3fdb05cb97f",
    "us-production": "56d5864cee357897b263c3133cac3ca0",
    development: "75e55ed1cd55ea25696b23a20247e786",
  };

  return mixpanelTokenMap[envName];
};
