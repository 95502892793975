import { apiOriginFromOrigin, mixpanelTokenFromOrigin } from "@/src/config/env";
import { MixpanelClient } from "@/src/lib/mixpanelClient";
import {
  type AuthJwt,
  type CreateSessionFromZenotiLoginRequest,
  type CreateUserRequest,
  type SessionCreateFromZenotiLoginResponse,
  TownhouseApiClient,
  TownhouseApiError,
  type UserCreateResponse,
  type UserId,
} from "@/src/lib/townhouseApiClient";
// biome-ignore lint/style/noNamespaceImport: Sentry has to be imported like this
import * as Sentry from "@sentry/vue";
import { defineStore } from "pinia";

export type AuthState = {
  browserId: `${string}-${string}-${string}-${string}-${string}`;
  townhouseApiClient: TownhouseApiClient;
  authJwt: AuthJwt | null;
  userId: UserId | null;
  isLoggingIn: boolean;
  isCreatingAccount: boolean;
  analytics: MixpanelClient;
};

export const isAuthState = (state: unknown): state is AuthState => {
  return typeof state === "object" && state !== null && "authJwt" in state;
};

export const useAuthStore = defineStore("auth", {
  state: (): AuthState => {
    return {
      browserId: crypto.randomUUID(),
      townhouseApiClient: new TownhouseApiClient(new URL(apiOriginFromOrigin())),
      authJwt: null,
      userId: null,
      isLoggingIn: false,
      isCreatingAccount: false,
      analytics: new MixpanelClient(mixpanelTokenFromOrigin()),
    };
  },
  persist: {
    storage: localStorage,
    pick: ["authJwt"],
    afterHydrate: () => {
      const authStore = useAuthStore();
      authStore.townhouseApiClient.setAuthJwt(authStore.authJwt);
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.authJwt,
  },
  actions: {
    // We can't use hydrate since we have to use async, so this has to be called when the app is first mounted
    async verifySession(): Promise<void> {
      if (!this.authJwt) {
        return;
      }
      try {
        const res = await this.townhouseApiClient.sessionGetCurrent();
        this.$patch((state) => {
          state.userId = res.sub;
          state.townhouseApiClient.setAuthJwt(state.authJwt);
        });

        Sentry.setUser(this.userId ? { id: this.userId } : null);
      } catch (e) {
        if (e instanceof TownhouseApiError) {
          return this.logout();
        }

        throw e;
      }
    },

    async createAccount(params: CreateUserRequest): Promise<void> {
      this.$patch({
        isCreatingAccount: true,
      });

      let createAccountRes: UserCreateResponse;
      try {
        createAccountRes = await this.townhouseApiClient.userCreate(params);
      } catch (e) {
        this.$patch({
          isCreatingAccount: false,
        });

        this.analytics.track("Account Creation Failed", {});

        throw e;
      }

      this.$patch((state) => {
        state.userId = createAccountRes.id;
        state.authJwt = createAccountRes.token;
        state.townhouseApiClient.setAuthJwt(state.authJwt);
        this.isCreatingAccount = false;
      });

      this.analytics.track("Account Created", {});

      try {
        this.analytics.identify(createAccountRes.id);
      } catch (e) {
        Sentry.captureException(e);
      }

      Sentry.setUser(this.userId ? { id: this.userId } : null);
    },

    async login(params: CreateSessionFromZenotiLoginRequest): Promise<void> {
      this.$patch({
        isLoggingIn: true,
      });

      let loginRes: SessionCreateFromZenotiLoginResponse;
      try {
        loginRes = await this.townhouseApiClient.sessionCreateFromZenotiLogin(params);
      } catch (e) {
        this.$patch({
          isLoggingIn: false,
        });

        this.analytics.track("Log In Failed", {});

        throw e;
      }

      this.analytics.track("Log In Success", {});

      this.$patch((state) => {
        state.userId = loginRes.userId;
        state.authJwt = loginRes.token;
        state.townhouseApiClient.setAuthJwt(state.authJwt);
        state.isLoggingIn = false;
      });

      try {
        this.analytics.identify(loginRes.userId);
      } catch (e) {
        Sentry.captureException(e);
      }

      Sentry.setUser(this.userId ? { id: this.userId } : null);
    },

    logout() {
      this.analytics.track("Logged Out", {});

      try {
        this.analytics.reset();
      } catch (e) {
        Sentry.captureException(e);
      }

      this.$reset();

      Sentry.setUser(null);
    },
  },
});
