<script setup lang="ts">
import BookingSummary from "@/src/components/BookingSummary.vue";
import BookingSummarySkeleton from "@/src/components/BookingSummarySkeleton.vue";
import Calendar from "@/src/components/Calendar.vue";
import Icon from "@/src/components/Icon.vue";
import NavigationTop from "@/src/components/NavigationTop.vue";
import SectionError from "@/src/components/SectionError.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import TextHeader2 from "@/src/components/TextHeader2.vue";
import { bookingToBookingSummary } from "@/src/lib/summaryHelpers";
import type { BookingId } from "@/src/lib/townhouseApiClient";
import { useBookingRescheduleStore } from "@/src/stores/bookingRescheduleStore";
import { useUserStore } from "@/src/stores/userStore";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { DateTime } from "luxon";
import { storeToRefs } from "pinia";
import { computed, onMounted, useTemplateRef, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const props = defineProps<{
  bookingId: BookingId;
}>();

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const bookingRescheduleStore = useBookingRescheduleStore();
const userStore = useUserStore();

const {
  rescheduledDateUtc,
  rescheduledDateTimeUtc,
  availableDateTimesUtc,
  fetchAvailableTimesFailure,
  confirmBookingRescheduleFailure,
} = storeToRefs(bookingRescheduleStore);
const { userBooking, fetchUserBookingFailure } = storeToRefs(userStore);

const rescheduleConfirmationErrorSection = useTemplateRef("resecheduleConfirmationErrorSection");

const minDate = DateTime.now();
const maxDate = minDate.plus({ months: 3 });

const bookingSummary = computed(() => (userBooking.value ? bookingToBookingSummary(userBooking.value) : null));

const handleBack = () => {
  router.push({ path: "/account/bookings", query: { returnTo: route.query["returnTo"] } });
};

const handleAvailableTimesTryAgain = () => {
  window.location.reload();
};

watch(rescheduleConfirmationErrorSection, (newRescheduleConfirmationErrorSection) => {
  newRescheduleConfirmationErrorSection?.scrollIntoView({ behavior: "smooth" });
});

onMounted(() => {
  if (!bookingRescheduleStore.bookingId) {
    bookingRescheduleStore.setBookingId(props.bookingId);
  }

  if (!bookingRescheduleStore.rescheduledDateUtc) {
    bookingRescheduleStore.setRescheduledDateUtc(DateTime.now().toUTC());
  }
});

const onTimeChosen = (newRescheduledDateTimeUtc: DateTime | string) => {
  // It's always a date/time, we just can't prove it to Typescript
  bookingRescheduleStore.setRescheduledDateTimeUtc(newRescheduledDateTimeUtc as DateTime);
};

watch(rescheduledDateUtc, async (newRescheduledDateUtc) => {
  if (!newRescheduledDateUtc) {
    return;
  }

  rescheduledDateTimeUtc.value = null;
  availableDateTimesUtc.value = null;
  await bookingRescheduleStore.fetchAvailableTimes();
});
</script>

<template>
  <div class="w-full max-w-[1140px] flex flex-col justify-center items-center relative">
    <TextHeader1 class="p-10">{{ t("account.bookings.reschedule.label") }}</TextHeader1>
    <NavigationTop @back="handleBack" />
    <div class="w-full flex flex-col gap-5 px-5">
      <div v-if="fetchAvailableTimesFailure || fetchUserBookingFailure" class="flex justify-center" >
        <SectionError :error-message="t('account.bookings.reschedule.availableTimesError')" :try-again-handler="handleAvailableTimesTryAgain" class="max-w-[600px]"/>  
      </div>
      <div v-else>
        <div v-if="confirmBookingRescheduleFailure" class="flex justify-center" ref="resecheduleConfirmationErrorSection">
          <SectionError :error-message="t('account.bookings.reschedule.confirmBookingRescheduleError')" class="max-w-[600px]"/>  
        </div>
        <div class="w-full flex flex-col md:flex-row gap-10 mt-10">
          <div class="w-full">
            <div v-if="bookingSummary" class="w-full mb-5 border-2 p-10 border-bookings-border">
              <BookingSummary
                hide-services
                :edit-mode="false"
                :location-name="bookingSummary.locationName"
                :address-one="bookingSummary.addressOne"
                :address-two="bookingSummary.addressTwo || undefined"
                :city="bookingSummary.city"
                :state="bookingSummary.state || undefined"
                :postal-code="bookingSummary.postalCode"
                :services="bookingSummary.serviceSummariesByGuest"
                :booking-date-time-utc="bookingSummary.bookingDateTimeUtc"
              />
            </div>
            <BookingSummarySkeleton v-else class="w-full mb-5 border-2 p-10 border-bookings-border"/>
            <Calendar class="w-full" v-model="rescheduledDateUtc" :min-date="minDate" :max-date="maxDate" />
          </div>
          <div class="w-full flex flex-col gap-5 md:border-available-times-border md:border md:p-5">
            <h3 class="flex justify-center">
              <TextHeader2>{{ t('dateTime.allTimes') }}</TextHeader2>
            </h3>
            <div v-if="bookingSummary && availableDateTimesUtc && availableDateTimesUtc.length > 0">
              <div role="radiogroup" class="w-full flex flex-wrap gap-5">
                <div v-for="availableDateTimeUtc in availableDateTimesUtc" class="w-[calc(33.333%-0.833rem)] lg:w-[calc(20%-1rem)] shrink-0 grow-0">
                  <button
                  :class="`block w-full p-5 ${rescheduledDateTimeUtc?.equals(availableDateTimeUtc) ? 'bg-button-grid-selected' : 'bg-button-grid-unselected'}`"
                  @click="onTimeChosen(availableDateTimeUtc)"
                  role="radio"
                  >
                    <TextBody1>
                      {{ availableDateTimeUtc.toLocal().toFormat(t('dateTime.timeFormat')) }}
                    </TextBody1>
                  </button>
                </div>
              </div>
            </div>
            <div v-else-if="availableDateTimesUtc && availableDateTimesUtc.length === 0" class="w-full h-full flex items-center justify-center">
              <TextBody1>{{ t('dateTime.noTimesLeft') }}</TextBody1>
            </div>
            <div v-else class="w-full h-full flex flex-col gap-4 items-center justify-center">
              <Icon :icon="faCircleNotch" size="2x" class="animate-spin"/>
              <TextBody1>{{t('dateTime.loading')}}</TextBody1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
