import { currencyFromOrigin } from "@/src/config/env";
import { serviceToSummary } from "@/src/lib/summaryHelpers";
import type {
  AddOn,
  AddOnId,
  AddOnName,
  BookingGetAvailableEmployeesResponse,
  BookingReservationId,
  CreateBookingRequest,
  Employee,
  LocationAddressOne,
  LocationAddressTwo,
  LocationCity,
  LocationGetInfoResponse,
  LocationId,
  LocationName,
  LocationPostalCode,
  LocationState,
  MedicalOrAccessNotesInfo,
  PaymentAccount,
  Removal,
  RemovalId,
  RemovalName,
  ServiceId,
  ServiceName,
  ServiceType,
  ServicesGetByLocationResponse,
  ServicesGetByLocationResponseItem,
  UserGetPaymentAccountsByLocationResponse,
  ZenotiPaymentAccountId,
  servicesGetByLocationResponseSchema,
} from "@/src/lib/townhouseApiClient";
import { useAuthStore } from "@/src/stores/authStore";
// biome-ignore lint/style/noNamespaceImport: Sentry has to be imported like this
import * as Sentry from "@sentry/vue";
import _ from "lodash";
import { DateTime } from "luxon";
import { type StateTree, defineStore } from "pinia";
import { z } from "zod";

export type GuestNumber = 1 | 2 | 3 | 4 | 5 | 6;

export type SingleServiceSummary = {
  serviceName: ServiceName;
  addOnNames: AddOnName[];
  removalNames: RemovalName[];
};

export type ParallelServiceSummary = {
  serviceOne: SingleServiceSummary;
  serviceTwo: SingleServiceSummary;
};

export type ServiceSummary = SingleServiceSummary | ParallelServiceSummary;

export const isSingleServiceSummary = (summary: ServiceSummary): summary is SingleServiceSummary => {
  return "serviceName" in summary;
};

export const isParallelServiceSummary = (summary: ServiceSummary): summary is ParallelServiceSummary => {
  return "serviceOne" in summary;
};

export type BookingSummary = {
  locationName: LocationName;
  addressOne: LocationAddressOne;
  addressTwo: LocationAddressTwo | null;
  city: LocationCity;
  state: LocationState | null;
  postalCode: LocationPostalCode;
  // Outer array per guest, inner array per service (single or parallel)
  serviceSummariesByGuest: ServiceSummary[][];
  bookingDateTimeUtc: DateTime;
};

const serviceForGuestSchema = z.object({
  addOnIds: z.array(z.string()).transform((arr) => new Set(arr) as Set<AddOnId>),
  needsRemoval: z.boolean().nullable(),
  removalIds: z.array(z.string()).transform((arr) => new Set(arr) as Set<RemovalId>),
  inParallelWith: z
    .string()
    .transform((s) => s as ServiceId)
    .nullable(),
});

const serviceByGuestSchema = z
  .record(z.string(), serviceForGuestSchema)
  .transform((obj) => new Map(Object.entries(obj)) as Map<ServiceId, (typeof obj)[string]>);

export type ServiceForGuest = z.output<typeof serviceForGuestSchema>;
export type ServiceByGuest = z.output<typeof serviceByGuestSchema>;
export type ServicesByLocation = z.output<typeof servicesGetByLocationResponseSchema>;
// This is the simplest way to get the type of the value of the map above
export type ServiceByLocation = ServicesByLocation extends Map<ServiceId, infer U> ? U : never;

const bookingStatePersistedSchema = z.object({
  locationId: z
    .string()
    .transform((s) => s as LocationId)
    .nullable(),
  isGroupPackageChosen: z.boolean().default(false),
  servicesByGuest: z.array(serviceByGuestSchema),
  bookingComplete: z.boolean().default(false),
  isPrBooking: z.boolean().default(false),
  updatedAt: z
    .string()
    .datetime()
    .transform((s) => DateTime.fromISO(s, { zone: "UTC" }))
    // TODO: make this nullable as per expand and contract TH-1480
    .nullish(),
});

export type BookingState = z.output<typeof bookingStatePersistedSchema> & {
  locationInfo: LocationGetInfoResponse | null;
  servicesByLocation: ServicesGetByLocationResponse | null;
  bookingDateUtc: DateTime | null;
  bookingDateTimeUtc: DateTime | null;
  availableEmployees: BookingGetAvailableEmployeesResponse["employees"] | null;
  employeeChosen: Employee | null;
  availableDateTimesUtc: DateTime[] | null;
  bookingReservationId: BookingReservationId | null;
  expiryDateTimeUtc: DateTime | null;
  didYouKnowPopupVisible: boolean;
  paymentAccounts: UserGetPaymentAccountsByLocationResponse | null;
  paymentAccountChosen: PaymentAccount | null;
  // FIXME: This should be defaulted to false
  termsAndConditionsAccepted: boolean | null;
  medicalOrAccessNotes: MedicalOrAccessNotesInfo | null;
  addPaymentAccountUrl: string | null;
  isConfirmingBooking: boolean;
  isFetchingPaymentAccounts: boolean;
  isReservingBooking: boolean;
  isReservationExpired: boolean | null;
  updatedAt: DateTime | null;
  // We have to omit 'cause: unknown' because Pinia's _DeepPartial can't recursively use
  // Partial<T> on 'unknown' types
  confirmBookingFailure: Omit<Error, "cause"> | null;
  createPaymentAccountFailure: Omit<Error, "cause"> | null;
  deletePaymentAccountFailure: Omit<Error, "cause"> | null;
  fetchAvailableEmployeesFailure: Omit<Error, "cause"> | null;
  fetchAvailableTimesFailure: Omit<Error, "cause"> | null;
  fetchPaymentAccountsFailure: Omit<Error, "cause"> | null;
  fetchServicesFailure: Omit<Error, "cause"> | null;
  reserveBookingFailure: Omit<Error, "cause"> | null;
};

export const isBookingState = (state: unknown): state is BookingState => {
  return typeof state === "object" && state !== null && "servicesByGuest" in state;
};

export class BookingStoreNotExistentGuestError extends Error {}
export class BookingStoreCannotRemoveGroupPackageIndividuallyError extends Error {}
export class BookingStoreNotEnoughGuestsForGroupPackageError extends Error {}
export class BookingStoreInvalidServiceError extends Error {}
export class BookingStoreServiceAlreadyAddedError extends Error {}
export class BookingStoreNonExistentParallelServiceError extends Error {}
export class BookingStoreNonExistentServiceError extends Error {}
export class BookingStoreInvalidBookingDateTimeError extends Error {}
export class BookingStoreIncompleteError extends Error {}
export class BookingStoreNoReservationError extends Error {}

export const useBookingStore = defineStore("booking", {
  state: (): BookingState => {
    return {
      locationId: null,
      locationInfo: null,
      servicesByLocation: null,
      isGroupPackageChosen: false,
      servicesByGuest: [],
      bookingDateUtc: null,
      bookingDateTimeUtc: null,
      availableEmployees: null,
      employeeChosen: null,
      availableDateTimesUtc: null,
      bookingReservationId: null,
      expiryDateTimeUtc: null,
      didYouKnowPopupVisible: false,
      paymentAccounts: null,
      paymentAccountChosen: null,
      termsAndConditionsAccepted: null,
      bookingComplete: false,
      medicalOrAccessNotes: null,
      addPaymentAccountUrl: null,
      isPrBooking: false,
      isConfirmingBooking: false,
      isFetchingPaymentAccounts: false,
      isReservingBooking: false,
      isReservationExpired: null,
      confirmBookingFailure: null,
      createPaymentAccountFailure: null,
      deletePaymentAccountFailure: null,
      fetchAvailableEmployeesFailure: null,
      fetchAvailableTimesFailure: null,
      fetchPaymentAccountsFailure: null,
      fetchServicesFailure: null,
      reserveBookingFailure: null,
      updatedAt: null,
    };
  },
  persist: {
    storage: localStorage,
    serializer: {
      serialize: (): string => {
        const bookingState = useBookingStore();

        return JSON.stringify({
          locationId: bookingState.locationId,
          isGroupPackageChosen: false,
          // ES6 Map can't be serialised to JSON, will result in `{}` so must be done by hand
          servicesByGuest: bookingState.$state.servicesByGuest.map((services) => {
            return _.mapValues(Object.fromEntries(services.entries()), (service) => {
              return {
                ...service,
                // Sets also can't be serialised to JSON, will also result in `{}` so must be done by hand
                addOnIds: Array.from(service.addOnIds),
                removalIds: Array.from(service.removalIds),
              };
            });
          }),
          bookingComplete: bookingState.bookingComplete,
          isPrBooking: bookingState.isPrBooking,
          updatedAt: bookingState.updatedAt,
          // It never makes sense to allow the user to leave the page, come back, and have these preserved
        });
      },
      deserialize: (data: string): StateTree => {
        try {
          const deserializedData: BookingState = JSON.parse(data);

          const state = bookingStatePersistedSchema.parse(deserializedData);

          if (state.updatedAt && state.updatedAt.diffNow("days").days <= -7) {
            return {};
          }

          return state;
        } catch (e) {
          Sentry.captureException(e);
          return {};
        }
      },
    },
  },
  getters: {
    numberOfGuests(): number {
      return this.servicesByGuest.length;
    },
    hasMultipleGuests(): boolean | null {
      if (this.servicesByGuest.length === 0) {
        return null;
      }

      return this.servicesByGuest.length > 1;
    },
    hasParallelService(): boolean | null {
      if (this.servicesByGuest.length === 0) {
        return null;
      }

      return Array.from(this.servicesByGuest.values()).some((servicesForGuest) => {
        return Array.from(servicesForGuest.values()).some((service) => service.inParallelWith !== null);
      });
    },
    hasSelectedNumberOfGuests(): boolean {
      return this.servicesByGuest.length > 0;
    },
    hasPopulatedAllGuests(): boolean {
      return (
        this.servicesByGuest.length > 0 && this.servicesByGuest.every((servicesForGuest) => servicesForGuest.size > 0)
      );
    },
    servicesByLocationGroupedByType(): Record<ServiceType, ServicesGetByLocationResponseItem[]> {
      const servicesArray = Array.from(this.servicesByLocation?.values() || []);
      return _.merge(
        {
          manicure: [],
          pedicure: [],
          maniPediManicure: [],
          maniPediPedicure: [],
          groupPackage: [],
          other: [],
        },
        _.groupBy(servicesArray, (service) => service.serviceType),
      );
    },
    chosenGroupPackage(): ServiceId | null {
      if (this.servicesByGuest.length === 0 || !this.isGroupPackageChosen) {
        return null;
      }

      return Array.from(this.servicesByGuest[0].keys())[0] || null;
    },
  },
  actions: {
    setLocation(locationId: LocationId, isPrBooking: boolean) {
      const authStore = useAuthStore();
      if (this.locationId === locationId) {
        return;
      }

      Sentry.setTag("locationId", locationId);
      Sentry.setTag("isPrBooking", isPrBooking);

      this.$reset();
      this.$patch({
        locationId,
        isPrBooking,
      });

      authStore.analytics.track("Location Selected", {
        locationId: locationId,
        isPrBooking: this.isPrBooking,
      });
    },

    async fetchLocationInfo(): Promise<void> {
      if (!this.locationId) {
        throw new BookingStoreIncompleteError("Unable to fetch location info as no location has been selected");
      }

      const authStore = useAuthStore();

      if (this.locationInfo) {
        return;
      }

      const locationInfo = await authStore.townhouseApiClient.locationGetInfo(this.locationId);
      this.$patch({
        locationInfo,
      });
    },

    async fetchServices(): Promise<void> {
      this.$patch({
        fetchServicesFailure: null,
      });

      if (!this.locationId) {
        const error = new BookingStoreIncompleteError("Unable to fetch services as no location has been selected");

        this.$patch({
          fetchServicesFailure: error,
        });

        throw error;
      }

      const authStore = useAuthStore();

      if (this.servicesByLocation) {
        return;
      }

      try {
        if (this.isPrBooking) {
          const servicesByLocation = await authStore.townhouseApiClient.prServicesGetByLocation(this.locationId);
          this.$patch({
            servicesByLocation,
          });
        } else {
          const servicesByLocation = await authStore.townhouseApiClient.servicesGetByLocation(this.locationId);

          this.$patch({
            servicesByLocation,
          });
        }
      } catch (e) {
        if (e instanceof Error) {
          this.$patch({
            fetchServicesFailure: e,
          });
        }

        throw e;
      }
    },

    getAddOnsFromServiceId(serviceId: ServiceId): AddOn[] {
      const service = this.servicesByLocation?.get(serviceId);

      if (!service) {
        throw new BookingStoreNonExistentServiceError(
          `Failed to get service details for service with ID: '${serviceId}'`,
        );
      }

      return Array.from(service.addOns.values());
    },

    getRemovalsFromServiceId(serviceId: ServiceId): Removal[] {
      const service = this.servicesByLocation?.get(serviceId);
      if (!service) {
        throw new BookingStoreNonExistentServiceError(
          `Failed to get service details for service with ID: '${serviceId}'`,
        );
      }
      return Array.from(service.removals.values());
    },

    copyServicesToAllGuests() {
      const firstGuestServices = this.servicesByGuest[0];

      if (!firstGuestServices) {
        throw new BookingStoreNonExistentServiceError("Failed to get service details for first guest");
      }

      const newServicesByGuest = this.servicesByGuest.map((services, index) => {
        if (index === 0) {
          return services;
        }
        return _.cloneDeep(firstGuestServices);
      });

      this.$patch({
        servicesByGuest: newServicesByGuest,
      });

      // Find parallel services in first guest's services
      const parallelServices = Array.from(firstGuestServices.entries()).filter(
        ([_, service]) => service.inParallelWith !== null,
      );

      const authStore = useAuthStore();

      if (parallelServices.length === 2) {
        const [serviceOne, serviceTwo] = parallelServices;
        authStore.analytics.track("Parallel Service Copied To All Guests", {
          numberOfGuests: this.servicesByGuest.length,
          serviceOneId: serviceOne[0],
          serviceOneAddOnIds: Array.from(serviceOne[1].addOnIds),
          serviceOneRemovalIds: Array.from(serviceOne[1].removalIds),
          serviceTwoId: serviceTwo[0],
          serviceTwoAddOnIds: Array.from(serviceTwo[1].addOnIds),
          serviceTwoRemovalIds: Array.from(serviceTwo[1].removalIds),
          locationId: this.locationId as LocationId,
          isPrBooking: this.isPrBooking,
        });

        return;
      }

      authStore.analytics.track("Service Copied To All Guests", {
        numberOfGuests: this.servicesByGuest.length,
        serviceId: Array.from(firstGuestServices.keys())[0],
        addOnIds: Array.from(Array.from(firstGuestServices.values())[0].addOnIds),
        removalIds: Array.from(Array.from(firstGuestServices.values())[0].removalIds),
        locationId: this.locationId as LocationId,
        isPrBooking: this.isPrBooking,
      });
    },

    setNumberOfGuests(numberOfGuests: GuestNumber) {
      const authStore = useAuthStore();

      if (numberOfGuests === this.servicesByGuest.length) {
        return;
      }

      authStore.analytics.track("Number of Guests Selected", {
        numberOfGuests: numberOfGuests,
        locationId: this.locationId as LocationId,
        isPrBooking: this.isPrBooking,
      });

      Sentry.setTag("numberOfGuests", numberOfGuests);

      if (this.isGroupPackageChosen && numberOfGuests <= 2) {
        this.$patch({
          servicesByGuest: [],
          isGroupPackageChosen: false,
        });
      }

      if (numberOfGuests < this.servicesByGuest.length) {
        // Truncate the array if needed
        this.$patch({
          servicesByGuest: this.servicesByGuest.slice(0, numberOfGuests),
        });

        this.clearBookingDatesAndTimes();
        return;
      }

      // Expand the array if a higher number of guests are chosen than previous
      if (this.isGroupPackageChosen) {
        this.$patch((state) => {
          // If a group package is currently chosen and more guests are added than initially specified
          // we want all the new guests to have the group package service chosen but no add ons chosen
          const [id, service] = Array.from(state.servicesByGuest[0])[0];
          _.times(numberOfGuests - this.servicesByGuest.length, () =>
            state.servicesByGuest.push(new Map(new Map([[id, { ...service, addOnIds: new Set([]) }]]))),
          );
        });

        return;
      }

      this.$patch((state) => {
        _.times(numberOfGuests - this.servicesByGuest.length, () => state.servicesByGuest.push(new Map()));
      });

      this.clearBookingDatesAndTimes();
    },

    addServiceToGuest(guestNumber: GuestNumber, serviceId: ServiceId) {
      this.$patch((state) => {
        if (!state.servicesByGuest[guestNumber - 1]) {
          throw new BookingStoreNotExistentGuestError(
            `Attempted to add a service to guest ${guestNumber} but there are only ${state.servicesByGuest.length} guests`,
          );
        }

        if (state.servicesByGuest[guestNumber - 1].has(serviceId)) {
          throw new BookingStoreServiceAlreadyAddedError(
            `The service with ID '${serviceId}' is already added to guest ${guestNumber}`,
          );
        }

        const service = this.servicesByLocation?.get(serviceId);

        if (!service) {
          throw new BookingStoreInvalidServiceError(
            `The service with ID '${serviceId}' is not available at location '${this.locationId}'`,
          );
        }

        if (service.serviceType === "groupPackage") {
          throw new BookingStoreInvalidServiceError(
            `The service with ID '${serviceId}' is a group package and cannot be added to a guest individually`,
          );
        }

        state.servicesByGuest[guestNumber - 1].set(serviceId, {
          addOnIds: new Set(),
          needsRemoval: null,
          removalIds: new Set(),
          inParallelWith: null,
        });
      });

      const authStore = useAuthStore();

      authStore.analytics.track("Service Added", {
        guestNumber: guestNumber,
        serviceId: serviceId,
        locationId: this.locationId as LocationId,
        isPrBooking: this.isPrBooking,
      });

      this.clearBookingDatesAndTimes();
    },

    addParallelServicesToGuest(guestNumber: GuestNumber, serviceIdOne: ServiceId, serviceIdTwo: ServiceId) {
      this.$patch((state) => {
        if (!state.servicesByGuest[guestNumber - 1]) {
          throw new BookingStoreNotExistentGuestError(
            `Attempted to add parallel services to guest ${guestNumber} but there are only ${state.servicesByGuest.length} guests`,
          );
        }

        if (state.servicesByGuest[guestNumber - 1].has(serviceIdOne)) {
          throw new BookingStoreServiceAlreadyAddedError(
            `The service with ID '${serviceIdOne}' is already added to guest ${guestNumber}`,
          );
        }

        if (state.servicesByGuest[guestNumber - 1].has(serviceIdTwo)) {
          throw new BookingStoreServiceAlreadyAddedError(
            `The service with ID '${serviceIdTwo}' is already added to guest ${guestNumber}`,
          );
        }

        if (!this.servicesByLocation?.has(serviceIdOne)) {
          throw new BookingStoreInvalidServiceError(
            `The service with ID '${serviceIdOne}' is not available at location '${this.locationId}'`,
          );
        }

        if (!this.servicesByLocation?.has(serviceIdTwo)) {
          throw new BookingStoreInvalidServiceError(
            `The service with ID '${serviceIdTwo}' is not available at location '${this.locationId}'`,
          );
        }

        state.servicesByGuest[guestNumber - 1].set(serviceIdOne, {
          addOnIds: new Set(),
          needsRemoval: null,
          removalIds: new Set(),
          inParallelWith: serviceIdTwo,
        });

        state.servicesByGuest[guestNumber - 1].set(serviceIdTwo, {
          addOnIds: new Set(),
          needsRemoval: null,
          removalIds: new Set(),
          inParallelWith: serviceIdOne,
        });
      });

      const authStore = useAuthStore();

      authStore.analytics.track("Parallel Services Added", {
        guestNumber: guestNumber,
        serviceOneId: serviceIdOne,
        serviceTwoId: serviceIdTwo,
        locationId: this.locationId as LocationId,
        isPrBooking: this.isPrBooking,
      });

      this.clearBookingDatesAndTimes();
    },

    addGroupPackageToAllGuests(serviceId: ServiceId) {
      if (this.servicesByGuest.length < 3) {
        throw new BookingStoreNotEnoughGuestsForGroupPackageError(
          "Attempted to add a group package when there are less than 3 guests",
        );
      }

      const service = this.servicesByLocation?.get(serviceId);

      if (!service) {
        throw new BookingStoreInvalidServiceError(
          `The service with ID '${serviceId}' is not available at location '${this.locationId}'`,
        );
      }

      if (service.serviceType !== "groupPackage") {
        throw new BookingStoreInvalidServiceError(`The service with ID '${serviceId}' is not a group package`);
      }

      this.$patch((state) => {
        for (let guestNumber = 1; guestNumber <= this.numberOfGuests; guestNumber++) {
          if (state.servicesByGuest[guestNumber - 1].size > 0) {
            throw new BookingStoreServiceAlreadyAddedError(
              `Another service with ID '${serviceId}' is already added to guest ${guestNumber}`,
            );
          }

          state.servicesByGuest[guestNumber - 1].set(serviceId, {
            addOnIds: new Set(),
            needsRemoval: null,
            removalIds: new Set(),
            inParallelWith: null,
          });
        }

        state.isGroupPackageChosen = true;
      });

      const authStore = useAuthStore();

      authStore.analytics.track("Group Package Added", {
        numberOfGuests: this.numberOfGuests,
        serviceId: serviceId,
        locationId: this.locationId as LocationId,
        isPrBooking: this.isPrBooking,
      });

      this.clearBookingDatesAndTimes();
    },

    addAddOnToGuest(guestNumber: GuestNumber, serviceId: ServiceId, addOnId: AddOnId) {
      this.$patch((state) => {
        if (!state.servicesByGuest[guestNumber - 1]) {
          throw new BookingStoreNotExistentGuestError(
            `Attempted to add an add-on to guest ${guestNumber} but there are only ${state.servicesByGuest.length} guests`,
          );
        }

        const service = state.servicesByGuest[guestNumber - 1].get(serviceId);

        if (!service) {
          throw new BookingStoreNonExistentServiceError(
            `Attempted to add an add-on with ID '${addOnId}' to a service with ID '${serviceId}' that did not exist`,
          );
        }

        if (!this.servicesByLocation?.get(serviceId)?.addOns.get(addOnId)) {
          throw new BookingStoreInvalidServiceError(
            `The add-on with ID '${addOnId}' is not available at location '${this.locationId}'`,
          );
        }

        service.addOnIds.add(addOnId);
      });

      const authStore = useAuthStore();

      authStore.analytics.track("Add-On Added", {
        guestNumber: guestNumber,
        serviceId: serviceId,
        addOnId: addOnId,
        locationId: this.locationId as LocationId,
        isPrBooking: this.isPrBooking,
      });

      this.clearBookingDatesAndTimes();
    },

    setNeedsRemovalForGuest(guestNumber: GuestNumber, serviceId: ServiceId, needsRemoval: boolean) {
      this.$patch((state) => {
        if (!state.servicesByGuest[guestNumber - 1]) {
          throw new BookingStoreNotExistentGuestError(
            `Attempted to set needing removal on guest ${guestNumber} but there are only ${state.servicesByGuest.length} guests`,
          );
        }

        const service = state.servicesByGuest[guestNumber - 1].get(serviceId);

        if (!service) {
          throw new BookingStoreNonExistentServiceError(
            `Attempted to set needing removal on a service with ID '${serviceId}' that did not exist`,
          );
        }

        service.needsRemoval = needsRemoval;
        if (!needsRemoval) {
          service.removalIds.clear();
        }
      });

      const authStore = useAuthStore();

      authStore.analytics.track("Needs Removal Set", {
        guestNumber: guestNumber,
        serviceId: serviceId,
        needsRemoval: needsRemoval,
        locationId: this.locationId as LocationId,
        isPrBooking: this.isPrBooking,
      });

      this.clearBookingDatesAndTimes();
    },

    getNeedsRemovalForGuest(guestNumber: GuestNumber, serviceId: ServiceId): boolean | null {
      const guestServices = this.servicesByGuest[guestNumber - 1];

      if (!guestServices) {
        throw new BookingStoreNotExistentGuestError(
          `Attempted to get needs removal on guest ${guestNumber} but there are only ${this.servicesByGuest.length} guests`,
        );
      }

      const service = guestServices.get(serviceId);

      if (!service) {
        throw new BookingStoreNonExistentServiceError(
          `Attempted to get needs removal on a service with ID '${serviceId}' that did not exist`,
        );
      }

      return service.needsRemoval;
    },

    addRemovalToGuest(guestNumber: GuestNumber, serviceId: ServiceId, removalId: RemovalId) {
      this.$patch((state) => {
        if (!state.servicesByGuest[guestNumber - 1]) {
          throw new BookingStoreNotExistentGuestError(
            `Attempted to add a removal to guest ${guestNumber} but there are only ${state.servicesByGuest.length} guests`,
          );
        }

        const service = state.servicesByGuest[guestNumber - 1].get(serviceId);

        if (!service) {
          throw new BookingStoreNonExistentServiceError(
            `Attempted to add a removal with ID '${removalId}' to a service with ID '${serviceId}' that did not exist`,
          );
        }

        if (!this.servicesByLocation?.get(serviceId)?.removals.get(removalId)) {
          throw new BookingStoreInvalidServiceError(
            `The removal with ID '${removalId}' is not available at location '${this.locationId}'`,
          );
        }

        service.needsRemoval = true;
        service.removalIds.add(removalId);
      });

      const authStore = useAuthStore();

      authStore.analytics.track("Removal Added", {
        guestNumber: guestNumber,
        serviceId: serviceId,
        removalId: removalId,
        locationId: this.locationId as LocationId,
        isPrBooking: this.isPrBooking,
      });

      this.clearBookingDatesAndTimes();
    },

    removeAllServicesFromAllGuests() {
      this.$patch((state) => {
        for (const guestServices of state.servicesByGuest) {
          guestServices.clear();
        }
        state.isGroupPackageChosen = false;
      });

      const authStore = useAuthStore();

      authStore.analytics.track("All Services Removed From All Guests", {
        locationId: this.locationId as LocationId,
        isPrBooking: this.isPrBooking,
      });
    },

    removeAllServicesFromGuest(guestNumber: GuestNumber) {
      this.$patch((state) => {
        if (!state.servicesByGuest[guestNumber - 1]) {
          throw new BookingStoreNotExistentGuestError(
            `Attempted to remove all services from guest ${guestNumber} but there are only ${state.servicesByGuest.length} guests`,
          );
        }

        if (this.isGroupPackageChosen) {
          throw new BookingStoreCannotRemoveGroupPackageIndividuallyError(
            "Attempted to remove services from a single guest when a group package is chosen",
          );
        }

        state.servicesByGuest[guestNumber - 1].clear();
      });

      const authStore = useAuthStore();

      authStore.analytics.track("All Services Removed From Guest", {
        guestNumber: guestNumber,
        locationId: this.locationId as LocationId,
        isPrBooking: this.isPrBooking,
      });

      this.clearBookingDatesAndTimes();
    },

    removeServiceFromGuest(guestNumber: GuestNumber, serviceId: ServiceId) {
      if (this.isGroupPackageChosen) {
        throw new BookingStoreCannotRemoveGroupPackageIndividuallyError(
          "Attempted to remove a single service when a group package is chosen",
        );
      }

      this.$patch((state) => {
        if (!state.servicesByGuest[guestNumber - 1]) {
          throw new BookingStoreNotExistentGuestError(
            `Attempted to remove a service from guest ${guestNumber} but there are only ${state.servicesByGuest.length} guests`,
          );
        }

        state.servicesByGuest[guestNumber - 1].delete(serviceId);
      });

      const authStore = useAuthStore();

      authStore.analytics.track("Service Removed", {
        guestNumber: guestNumber,
        serviceId: serviceId,
        locationId: this.locationId as LocationId,
        isPrBooking: this.isPrBooking,
      });

      this.clearBookingDatesAndTimes();
    },

    removeParallelServicesFromGuest(guestNumber: GuestNumber, serviceIdOne: ServiceId, serviceIdTwo: ServiceId) {
      if (this.isGroupPackageChosen) {
        throw new BookingStoreCannotRemoveGroupPackageIndividuallyError(
          "Attempted to remove a single service when a group package is chosen",
        );
      }

      this.$patch((state) => {
        if (!state.servicesByGuest[guestNumber - 1]) {
          throw new BookingStoreNotExistentGuestError(
            `Attempted to remove parallel services from guest ${guestNumber} but there are only ${state.servicesByGuest.length} guests`,
          );
        }

        state.servicesByGuest[guestNumber - 1].delete(serviceIdOne);
        state.servicesByGuest[guestNumber - 1].delete(serviceIdTwo);
      });

      const authStore = useAuthStore();

      authStore.analytics.track("Parallel Services Removed", {
        guestNumber: guestNumber,
        serviceOneId: serviceIdOne,
        serviceTwoId: serviceIdTwo,
        locationId: this.locationId as LocationId,
        isPrBooking: this.isPrBooking,
      });

      this.clearBookingDatesAndTimes();
    },

    removeAddOnFromGuest(guestNumber: GuestNumber, serviceId: ServiceId, addOnId: AddOnId) {
      this.$patch((state) => {
        if (!state.servicesByGuest[guestNumber - 1]) {
          throw new BookingStoreNotExistentGuestError(
            `Attempted to remove an add-on from guest ${guestNumber} but there are only ${state.servicesByGuest.length} guests`,
          );
        }

        const service = state.servicesByGuest[guestNumber - 1].get(serviceId);

        if (!service) {
          throw new BookingStoreNonExistentServiceError(
            `Attempted to remove an add-on with ID '${addOnId}' from a service with ID '${serviceId}' that did not exist`,
          );
        }

        service.addOnIds.delete(addOnId);
      });

      const authStore = useAuthStore();

      authStore.analytics.track("Add-On Removed", {
        guestNumber: guestNumber,
        serviceId: serviceId,
        addOnId: addOnId,
        locationId: this.locationId as LocationId,
        isPrBooking: this.isPrBooking,
      });

      this.clearBookingDatesAndTimes();
    },

    removeRemovalFromGuest(guestNumber: GuestNumber, serviceId: ServiceId, removalId: RemovalId) {
      this.$patch((state) => {
        if (!state.servicesByGuest[guestNumber - 1]) {
          throw new BookingStoreNotExistentGuestError(
            `Attempted to remove a removal from guest ${guestNumber} but there are only ${state.servicesByGuest.length} guests`,
          );
        }

        const service = state.servicesByGuest[guestNumber - 1].get(serviceId);

        if (!service) {
          throw new BookingStoreNonExistentServiceError(
            `Attempted to remove a removal with ID '${removalId}' from a service with ID '${serviceId}' that did not exist`,
          );
        }

        service.removalIds.delete(removalId);
      });

      const authStore = useAuthStore();

      authStore.analytics.track("Removal Removed", {
        guestNumber: guestNumber,
        serviceId: serviceId,
        removalId: removalId,
        locationId: this.locationId as LocationId,
        isPrBooking: this.isPrBooking,
      });

      this.clearBookingDatesAndTimes();
    },

    clearRemovalsFromGuest(guestNumber: GuestNumber, serviceId: ServiceId) {
      this.$patch((state) => {
        if (!state.servicesByGuest[guestNumber - 1]) {
          throw new BookingStoreNotExistentGuestError(
            `Attempted to clear removals from guest ${guestNumber} but there are only ${state.servicesByGuest.length} guests`,
          );
        }

        const service = state.servicesByGuest[guestNumber - 1].get(serviceId);

        if (!service) {
          throw new BookingStoreNonExistentServiceError(
            `Attempted to clear removals from a service with ID '${serviceId}' that did not exist`,
          );
        }

        service.removalIds.clear();
      });

      const authStore = useAuthStore();

      authStore.analytics.track("Removal Cleared", {
        guestNumber: guestNumber,
        serviceId: serviceId,
        locationId: this.locationId as LocationId,
        isPrBooking: this.isPrBooking,
      });

      this.clearBookingDatesAndTimes();
    },

    clearBookingDatesAndTimes() {
      this.$patch({
        availableEmployees: null,
        employeeChosen: null,
        bookingDateUtc: null,
        bookingDateTimeUtc: null,
        availableDateTimesUtc: null,
        bookingReservationId: null,
        expiryDateTimeUtc: null,
      });
    },

    async fetchAvailableEmployees(): Promise<void> {
      this.$patch({
        fetchAvailableEmployeesFailure: null,
      });

      const authStore = useAuthStore();

      if (this.availableEmployees && this.availableEmployees.length > 0) {
        return;
      }

      if (!(authStore.isLoggedIn && authStore.userId)) {
        const error = new BookingStoreIncompleteError("Unable to fetch available employees as there is no user set");

        this.$patch({
          fetchAvailableEmployeesFailure: error,
        });

        throw error;
      }

      try {
        const createBookingRequest = this.constructCreateBookingRequest(DateTime.now().toUTC());
        const availableEmployees =
          await authStore.townhouseApiClient.bookingGetAvailableEmployees(createBookingRequest);

        this.$patch({
          availableEmployees: availableEmployees.employees,
        });
      } catch (e) {
        this.$patch({
          fetchAvailableEmployeesFailure: e instanceof Error ? e : null,
        });

        throw e;
      }
    },

    async setChosenEmployee(chosenEmployee: Employee | null): Promise<void> {
      const authStore = useAuthStore();

      this.$patch({
        employeeChosen: chosenEmployee,
      });

      if (this.availableDateTimesUtc) {
        await this.refetchAvailableTimes();
      }

      if (chosenEmployee) {
        authStore.analytics.track("Specific Employee Chosen", {
          employeeId: chosenEmployee.zenotiEmployeeId,
          numberOfGuests: this.servicesByGuest.length,
          locationId: this.locationId as LocationId,
          isPrBooking: this.isPrBooking,
        });
      } else {
        authStore.analytics.track("Any Employee Chosen", {
          numberOfGuests: this.servicesByGuest.length,
          locationId: this.locationId as LocationId,
          isPrBooking: this.isPrBooking,
        });
      }
    },

    setBookingDateUtc(bookingDateUtc: DateTime) {
      this.$patch({
        bookingDateUtc,
        bookingDateTimeUtc: null,
        availableDateTimesUtc: null,
        bookingReservationId: null,
        expiryDateTimeUtc: null,
      });
    },

    constructCreateBookingRequest(bookingDateTimeUtc: DateTime): CreateBookingRequest {
      if (!(this.locationId && this.hasPopulatedAllGuests)) {
        throw new BookingStoreIncompleteError(
          "Unable to start booking process as either the location is not set, or there are no guests with services",
        );
      }

      return {
        locationId: this.locationId,
        bookingDateTimeUtc,
        zenotiEmployeeId: this.employeeChosen?.zenotiEmployeeId || null,
        servicesByGuest: this.servicesByGuest.map((servicesForGuest) => {
          return Array.from(servicesForGuest.entries()).map(([serviceId, rest]) => {
            return {
              serviceId,
              addOnIds: Array.from(rest.addOnIds),
              removalIds: Array.from(rest.removalIds),
              inParallelWith: rest.inParallelWith,
            };
          });
        }),
        isPrBooking: this.isPrBooking,
      };
    },

    async fetchAvailableTimes(): Promise<void> {
      this.$patch({
        fetchAvailableTimesFailure: null,
      });

      const authStore = useAuthStore();

      if (this.availableDateTimesUtc && this.availableDateTimesUtc.length > 0) {
        return;
      }

      const bookingDateUtc = this.bookingDateUtc;

      if (!bookingDateUtc) {
        const error = new BookingStoreIncompleteError(
          "Unable to start booking process as booking date is not selected",
        );

        this.$patch({
          fetchAvailableTimesFailure: error,
        });

        throw error;
      }

      try {
        const createBookingRequest = this.constructCreateBookingRequest(bookingDateUtc);

        const availableDateTimesUtc = await authStore.townhouseApiClient.bookingGetAvailableTimes(createBookingRequest);

        // If after fetching the available times the booking date has changed, the calendar was clicked whilst we were
        // waiting to load. In this case, we should throw away the times we fetched or they will be populating a store
        // that is no longer wanting the times we just got.
        if (this.bookingDateUtc !== bookingDateUtc) {
          return;
        }

        this.$patch({
          availableDateTimesUtc,
          bookingDateTimeUtc: null,
          bookingReservationId: null,
          expiryDateTimeUtc: null,
        });
      } catch (e) {
        this.$patch({
          fetchAvailableTimesFailure: e instanceof Error ? e : null,
        });

        throw e;
      }

      authStore.analytics.track("Date Chosen", {
        dateUtc: bookingDateUtc.toString(),
      });
    },

    async refetchAvailableTimes(): Promise<void> {
      this.$patch({
        availableDateTimesUtc: null,
        bookingDateTimeUtc: null,
        bookingReservationId: null,
        expiryDateTimeUtc: null,
      });

      await this.fetchAvailableTimes();
    },

    setBookingDateTimeUtc(bookingDateTimeUtc: DateTime) {
      if (!this.availableDateTimesUtc?.includes(bookingDateTimeUtc)) {
        throw new BookingStoreInvalidBookingDateTimeError(
          "Unable to set booking date / time to any value that wasn't in the available times",
        );
      }

      this.$patch({
        bookingDateTimeUtc,
        bookingReservationId: null,
        expiryDateTimeUtc: null,
      });

      const authStore = useAuthStore();

      authStore.analytics.track("Time Chosen", {
        dateTimeUtc: bookingDateTimeUtc.toString(),
      });
    },

    createBookingSummary(): BookingSummary {
      if (!this.locationInfo) {
        throw new BookingStoreIncompleteError("Unable to get booking summary as location info has not been fetched");
      }

      if (!(this.servicesByLocation && this.hasPopulatedAllGuests && this.bookingDateTimeUtc)) {
        throw new BookingStoreIncompleteError("Unable to get booking summary as some details have not been selected");
      }

      // Typescript can't see this isn't null inside the serviceToSummary function otherwise
      const servicesByLocation = this.servicesByLocation;
      const serviceSummariesByGuest = this.servicesByGuest.map((servicesForGuest) =>
        Array.from(
          Array.from(servicesForGuest.entries())
            .reduce((acc, [serviceId, service]) => {
              if (service.inParallelWith) {
                const [firstServiceId, secondServiceId] = [serviceId, service.inParallelWith].sort();
                const firstService = servicesForGuest.get(firstServiceId);
                const secondService = servicesForGuest.get(secondServiceId);

                if (!(firstService && secondService)) {
                  throw new BookingStoreNonExistentParallelServiceError(
                    `Attempted to associate parallel services with IDs ${firstServiceId} and ${secondServiceId} where at least one does not exist`,
                  );
                }

                return acc.set(`${firstServiceId}-${secondServiceId}`, {
                  serviceOne: serviceToSummary(servicesByLocation, firstServiceId, firstService),
                  serviceTwo: serviceToSummary(servicesByLocation, secondServiceId, secondService),
                } as ParallelServiceSummary);
              }
              return acc.set(serviceId, serviceToSummary(servicesByLocation, serviceId, service));
            }, new Map<string, ServiceSummary>())
            .values(),
        ),
      );

      return {
        locationName: this.locationInfo.name,
        addressOne: this.locationInfo.addressLineOne,
        addressTwo: this.locationInfo.addressLineTwo,
        city: this.locationInfo.city,
        state: this.locationInfo.state,
        postalCode: this.locationInfo.postalCode,
        serviceSummariesByGuest,
        bookingDateTimeUtc: this.bookingDateTimeUtc,
      };
    },

    calculateTotalBookingPriceCents(): number {
      if (!(this.servicesByLocation && this.hasPopulatedAllGuests && this.bookingDateTimeUtc)) {
        throw new BookingStoreIncompleteError(
          "Unable to get booking total price as some details have not been selected",
        );
      }

      let bookingTotalPriceCents = 0;
      for (const servicesForGuest of this.servicesByGuest) {
        for (const [serviceId, service] of servicesForGuest) {
          const serviceInfo = this.servicesByLocation?.get(serviceId);
          if (!serviceInfo) {
            throw new BookingStoreNonExistentServiceError(`Attempted to find a price for service with ID ${serviceId}`);
          }

          const servicePriceCents = serviceInfo.priceCents;

          const totalAddOnsPriceCents = _.sum(
            Array.from(service.addOnIds).map((addOnId) => {
              const addOnPriceCents = serviceInfo.addOns.get(addOnId)?.priceCents;

              if (!addOnPriceCents) {
                throw new BookingStoreNonExistentServiceError(`Attempted to find a price for addOn with ID ${addOnId}`);
              }

              return addOnPriceCents;
            }),
          );

          const totalRemovalsPriceCents = _.sum(
            Array.from(service.removalIds).map((removalId) => {
              const addOnPriceCents = serviceInfo.removals.get(removalId)?.priceCents;

              if (!addOnPriceCents) {
                throw new BookingStoreNonExistentServiceError(
                  `Attempted to find a price for removal with ID ${removalId}`,
                );
              }

              return addOnPriceCents;
            }),
          );

          bookingTotalPriceCents += servicePriceCents + totalAddOnsPriceCents + totalRemovalsPriceCents;
        }
      }

      return bookingTotalPriceCents;
    },

    async fetchPaymentAccounts(): Promise<void> {
      const authStore = useAuthStore();

      this.$patch({
        fetchPaymentAccountsFailure: null,
        isFetchingPaymentAccounts: true,
      });

      if (this.paymentAccounts && this.paymentAccounts.length > 0) {
        this.$patch({
          isFetchingPaymentAccounts: false,
        });

        return;
      }

      if (!(authStore.isLoggedIn && authStore.userId)) {
        const error = new BookingStoreIncompleteError("Unable to fetch payment accounts as there is no user set");

        this.$patch({
          fetchPaymentAccountsFailure: error,
          isFetchingPaymentAccounts: false,
        });

        throw error;
      }

      try {
        const paymentAccounts = await authStore.townhouseApiClient.userGetPaymentAccounts(authStore.userId);

        this.$patch({
          paymentAccounts,
          paymentAccountChosen: paymentAccounts.length > 0 ? paymentAccounts[0] : null,
          isFetchingPaymentAccounts: false,
        });
      } catch (e) {
        this.$patch({
          fetchPaymentAccountsFailure: e instanceof Error ? e : null,
          isFetchingPaymentAccounts: false,
        });

        throw e;
      }
    },

    setChosenPaymentAccount(chosenPaymentAccount: PaymentAccount) {
      const authStore = useAuthStore();

      if (!(authStore.isLoggedIn && authStore.userId)) {
        throw new BookingStoreIncompleteError("Unable to set chosen payment account as there is no user set");
      }

      this.$patch({
        paymentAccountChosen: chosenPaymentAccount,
      });
    },

    async refetchPaymentAccounts(): Promise<void> {
      this.$patch({
        paymentAccounts: null,
        paymentAccountChosen: null,
      });

      await this.fetchPaymentAccounts();
    },

    async startCreatePaymentAccount(): Promise<void> {
      const authStore = useAuthStore();

      this.$patch({
        createPaymentAccountFailure: null,
        addPaymentAccountUrl: null,
      });

      if (!(authStore.isLoggedIn && authStore.userId)) {
        const error = new BookingStoreIncompleteError("Unable to create payment account as there is no user set");

        this.$patch({
          createPaymentAccountFailure: error,
        });

        throw error;
      }

      try {
        const createPaymentAccountResponse = await authStore.townhouseApiClient.userCreatePaymentAccount(
          authStore.userId,
        );

        this.$patch({
          addPaymentAccountUrl: createPaymentAccountResponse.url.toString(),
        });
      } catch (e) {
        this.$patch({
          createPaymentAccountFailure: e instanceof Error ? e : null,
        });

        throw e;
      }
    },

    clearPaymentAccountUrl() {
      this.$patch({
        addPaymentAccountUrl: null,
      });
    },

    async deletePaymentAccount(paymentAccountId: ZenotiPaymentAccountId): Promise<void> {
      const authStore = useAuthStore();

      this.$patch({
        deletePaymentAccountFailure: null,
      });

      if (!(authStore.isLoggedIn && authStore.userId)) {
        const error = new BookingStoreIncompleteError("Unable to delete payment account as there is no user set");

        this.$patch({
          deletePaymentAccountFailure: error,
        });

        throw error;
      }

      try {
        await authStore.townhouseApiClient.userDeletePaymentAccount(authStore.userId, paymentAccountId);
      } catch (e) {
        this.$patch({
          deletePaymentAccountFailure: e instanceof Error ? e : null,
        });

        throw e;
      }
    },

    clearBookingDateTimeUtc() {
      this.$patch({
        bookingDateUtc: null,
        availableDateTimesUtc: null,
        bookingDateTimeUtc: null,
        bookingReservationId: null,
        expiryDateTimeUtc: null,
      });
    },

    async reserveBooking(): Promise<void> {
      this.$patch({
        bookingReservationId: null,
        expiryDateTimeUtc: null,
        isReservingBooking: true,
        reserveBookingFailure: null,
        isReservationExpired: false,
      });

      if (!this.bookingDateTimeUtc) {
        const error = new BookingStoreIncompleteError(
          "Unable to start booking process as booking date / time is not selected",
        );

        this.$patch({
          isReservingBooking: false,
          reserveBookingFailure: error,
        });

        throw error;
      }

      let createBookingRequest: CreateBookingRequest;

      try {
        createBookingRequest = this.constructCreateBookingRequest(this.bookingDateTimeUtc);
      } catch (e) {
        this.$patch({
          isReservingBooking: false,
          reserveBookingFailure: e instanceof Error ? e : null,
        });

        throw e;
      }

      const authStore = useAuthStore();

      try {
        const createReservationResponse =
          await authStore.townhouseApiClient.bookingCreateReservation(createBookingRequest);

        this.$patch({
          ...createReservationResponse,
        });
      } catch (e) {
        this.$patch({
          isReservingBooking: false,
          reserveBookingFailure: e instanceof Error ? e : null,
        });

        throw e;
      }

      this.$patch({
        isReservingBooking: false,
      });

      authStore.analytics.track("Booking Reserved", {
        bookingReservationId: this.bookingReservationId as BookingReservationId,
        locationId: this.locationId as LocationId,
        dateTimeUtc: this.bookingDateTimeUtc.toString(),
      });
    },

    setReservationExpired(isReservationExpired: boolean) {
      this.$patch({
        isReservationExpired,
      });
    },

    async confirmBooking(): Promise<void> {
      this.$patch({
        confirmBookingFailure: null,
        isConfirmingBooking: true,
      });

      if (!this.bookingReservationId) {
        const error = new BookingStoreNoReservationError(
          "No booking reservation has been created so nothing can be confirmed",
        );

        this.$patch({
          isConfirmingBooking: false,
          confirmBookingFailure: error,
        });

        throw error;
      }

      const authStore = useAuthStore();

      try {
        await authStore.townhouseApiClient.bookingCreateConfirmation(this.bookingReservationId, {
          medicalOrAccessNotes: this.medicalOrAccessNotes,
        });
      } catch (e) {
        this.$patch({
          isConfirmingBooking: false,
          confirmBookingFailure: e instanceof Error ? e : null,
        });

        throw e;
      }

      this.$patch({
        isConfirmingBooking: false,
        bookingComplete: true,
      });

      authStore.analytics.track("Booking Confirmed", {
        locationId: this.locationId as LocationId,
        dateTimeUtc: this.bookingDateTimeUtc?.toString() || null,
      });

      const currency = currencyFromOrigin();

      // Push purchase event to the GTM data layer
      try {
        const addOnsToGtmPurchaseItems = (addOnIds: Iterable<AddOnId>, serviceInfo: ServiceByLocation | undefined) =>
          Array.from(addOnIds).map((addOnId) => {
            const addOnInfo = serviceInfo?.addOns.get(addOnId);
            return {
              // biome-ignore lint/style/useNamingConvention: GTM naming convention
              item_name: addOnInfo?.name || addOnId,
              // biome-ignore lint/style/useNamingConvention: GTM naming convention
              item_id: addOnId,
              quantity: 1,
              // GTM expects these in unit values, eg. pounds and dollars
              price: (addOnInfo?.priceCents || 0) / 100,
            };
          });

        const removalsToGtmPurchaseItems = (
          removalIds: Iterable<RemovalId>,
          serviceInfo: ServiceByLocation | undefined,
        ) =>
          Array.from(removalIds).map((removalId) => {
            const removalInfo = serviceInfo?.removals.get(removalId);

            return {
              // biome-ignore lint/style/useNamingConvention: GTM naming convention
              item_name: removalInfo?.name || removalId,
              // biome-ignore lint/style/useNamingConvention: GTM naming convention
              item_id: removalId,
              quantity: 1,
              // GTM expects these in unit values, eg. pounds and dollars
              price: (removalInfo?.priceCents || 0) / 100,
            };
          });

        const items = Array.from(this.servicesByGuest.entries()).flatMap(([_, services]) =>
          Array.from(services.entries()).flatMap(([serviceId, service]) => {
            const serviceInfo = this.servicesByLocation?.get(serviceId);

            const baseItem = {
              // biome-ignore lint/style/useNamingConvention: GTM naming convention
              item_name: serviceInfo?.name || serviceId,
              // biome-ignore lint/style/useNamingConvention: GTM naming convention
              item_id: serviceId,
              quantity: 1,
              // GTM expects these in unit values, eg. pounds and dollars
              price: (serviceInfo?.priceCents || 0) / 100,
            };

            return [
              baseItem,
              ...addOnsToGtmPurchaseItems(service.addOnIds, serviceInfo),
              ...removalsToGtmPurchaseItems(service.removalIds, serviceInfo),
            ];
          }),
        );

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "purchase",
          ecommerce: {
            currency,
            // biome-ignore lint/style/useNamingConvention: GTM naming convention
            transaction_id: this.bookingReservationId,
            value: this.calculateTotalBookingPriceCents() / 100, // Convert cents to currency units
            items,
          },
        });
      } catch (e) {
        console.warn("Ad blocker might be preventing GTM functionality", e);
      }
    },

    setMedicalOrAccessNotesInfo(medicalOrAccessNotes: string | null) {
      this.$patch({
        medicalOrAccessNotes: medicalOrAccessNotes ? medicalOrAccessNotes.trim() : null,
      });

      const authStore = useAuthStore();

      authStore.analytics.track("Medical or Access Needs Changed", {});
    },

    setIsPrBooking(isPr: boolean) {
      this.$patch({
        isPrBooking: isPr,
      });
    },

    setUpdatedAt() {
      this.$patch({
        updatedAt: DateTime.now().toUTC(),
      });
    },
  },
});
