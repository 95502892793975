<script setup lang="ts">
import DidYouKnowImageOne from "@/src/assets/did-you-know-variant-one.jpg";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

defineProps<{
  variant: "one";
}>();
</script>

<template>
  <div role="dialog" aria-modal="true" class="fixed inset-0 z-20 w-screen h-screen bg-popup-background p-10 flex justify-center items-start">
    <div class="w-[600px] max-h-[calc(100vh-2.5rem)] overflow-auto bg-white relative">
      <div class="w-full flex flex-col justify-center items-center">
        <TextHeader1 class="p-10 pb-0 text-center">{{ t("popup.didYouKnow.header") }}</TextHeader1>
        <div class="max-h-[calc(100vh-2.5rem)] overflow-auto p-10 pt-5 h-full flex items-center justify-center relative">
          <img :src="DidYouKnowImageOne" />
        </div>
      </div>
    </div>
  </div>
</template>
