<script setup lang="ts">
import GuestsGroupPackage from "@/src/components/GuestsGroupPackage.vue";
import GuestsMultiple from "@/src/components/GuestsMultiple.vue";
import GuestsMultipleSkeleton from "@/src/components/GuestsMultipleSkeleton.vue";
import SectionDivider from "@/src/components/SectionDivider.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import type { LocationId } from "@/src/lib/townhouseApiClient";
import { useBookingStore } from "@/src/stores/bookingStore";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const { t } = useI18n();
const bookingStore = useBookingStore();
const { isGroupPackageChosen } = storeToRefs(bookingStore);
const router = useRouter();

defineProps<{
  locationId: LocationId;
  press: boolean | undefined;
}>();

const { servicesByLocation, servicesByGuest } = storeToRefs(bookingStore);

const handleEditClicked = (guestNumberToEdit: number) => {
  if (bookingStore.isGroupPackageChosen) {
    return router.push({
      path: `/locations/${bookingStore.locationId}/guests/${guestNumberToEdit}/services/${bookingStore.chosenGroupPackage}/add-ons`,
    });
  }
  return router.push({
    path: `/locations/${bookingStore.locationId}/guests/${guestNumberToEdit}/services`,
  });
};
</script>

<template>
  <h2 class="flex justify-center mt-5 mb-10">
    <TextHeader1 class="text-center">{{ t('guests.header') }}</TextHeader1>
  </h2>
  <SectionDivider/>
  <GuestsGroupPackage
  v-if="isGroupPackageChosen && servicesByLocation"
  @copy="bookingStore.copyServicesToAllGuests();"
  @edit="handleEditClicked"
  :servicesByLocation="servicesByLocation!"
  :servicesByGuest="servicesByGuest"
  />
  <GuestsMultiple
  v-else-if="!isGroupPackageChosen && servicesByLocation"
  @copy="bookingStore.copyServicesToAllGuests();"
  @edit="handleEditClicked"
  :servicesByLocation="servicesByLocation!"
  :servicesByGuest="servicesByGuest"
  />
  <GuestsGroupPackageSkeleton v-else-if="isGroupPackageChosen" /> 
  <GuestsMultipleSkeleton v-else />
</template>
