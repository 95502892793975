<script setup lang="ts">
import ButtonInScreenTertiary from "@/src/components/ButtonInScreenTertiary.vue";
import Calendar from "@/src/components/Calendar.vue";
import Icon from "@/src/components/Icon.vue";
import SectionError from "@/src/components/SectionError.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import TextHeader2 from "@/src/components/TextHeader2.vue";
import TextHeader3 from "@/src/components/TextHeader3.vue";
import type { LocationId } from "@/src/lib/townhouseApiClient";
import PopupAuth from "@/src/popups/PopupAuth.vue";
import PopupReservationExpired from "@/src/popups/PopupReservationExpired.vue";
import PopupSelectEmployee from "@/src/popups/PopupSelectEmployee.vue";
import { useAuthStore } from "@/src/stores/authStore";
import { useBookingStore } from "@/src/stores/bookingStore";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { DateTime, Duration } from "luxon";
import { storeToRefs } from "pinia";
import { onMounted, onUnmounted, ref, useTemplateRef, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const { t } = useI18n();
const authStore = useAuthStore();
const bookingStore = useBookingStore();
const router = useRouter();

defineProps<{
  locationId: LocationId;
  press: boolean | undefined;
}>();

const { isLoggedIn } = storeToRefs(authStore);
const {
  hasMultipleGuests,
  hasParallelService,
  bookingDateUtc,
  bookingDateTimeUtc,
  availableDateTimesUtc,
  employeeChosen,
  fetchPaymentAccountsFailure,
  reserveBookingFailure,
  fetchAvailableTimesFailure,
  isReservationExpired,
} = storeToRefs(bookingStore);

const selectingEmployee = ref<boolean>(false);
const showMoreTimesClicked = ref<boolean>(false);

const minDate = DateTime.now();
const maxDate = minDate.plus({ months: 3 });

const pageErrorSection = useTemplateRef("pageErrorSection");

const initBookingDateUtc = (isLoggedIn: boolean) => {
  if (!isLoggedIn || bookingDateUtc.value) {
    return;
  }

  bookingStore.setBookingDateUtc(DateTime.now().toUTC());
};

onMounted(async () => {
  initBookingDateUtc(isLoggedIn.value);

  // Set up an interval to refresh any available times every 5 minutes
  const interval = setInterval(
    async () => {
      if (availableDateTimesUtc.value) {
        await bookingStore.refetchAvailableTimes();
      }
    },
    Duration.fromObject({ minutes: 5 }).as("milliseconds"),
  );

  // Clear the interval when the component is unmounted
  onUnmounted(() => {
    clearInterval(interval);
  });
});

watch(isLoggedIn, (newIsLoggedIn) => initBookingDateUtc(newIsLoggedIn));

const handleAuthCancelled = () => {
  router.back();
};

watch(pageErrorSection, (newPageErrorSection) => {
  newPageErrorSection?.scrollIntoView({ behavior: "smooth" });
});

const handleSelectEmployeeClicked = () => {
  bookingStore.fetchAvailableEmployees();

  selectingEmployee.value = true;
};

const handleSelectEmployeeCancelled = () => {
  selectingEmployee.value = false;
};

const handleReservationExpiredConfirmed = () => {
  bookingStore.setReservationExpired(false);
};

const onTimeChosen = (newBookingDateTimeUtc: DateTime | string) => {
  // It's always a date/time, we just can't prove it to Typescript
  bookingStore.setBookingDateTimeUtc(newBookingDateTimeUtc as DateTime);
};

const handleAvailableTimesTryAgain = () => {
  bookingStore.refetchAvailableTimes();
};

watch(bookingDateUtc, async (newBookingDateUtc) => {
  if (!newBookingDateUtc) {
    return;
  }

  showMoreTimesClicked.value = false;
  bookingDateTimeUtc.value = null;
  availableDateTimesUtc.value = null;
  await bookingStore.fetchAvailableTimes();
});
</script>

<template>
  <PopupReservationExpired
    v-if="isReservationExpired"
    :title="t('dateTime.reservationExpired.title')"
    :message="t('dateTime.reservationExpired.message')"
    :confirm="t('dateTime.reservationExpired.confirm')"
    @confirmed="handleReservationExpiredConfirmed"
    @cancelled="handleReservationExpiredConfirmed"
     />
  
  <PopupAuth v-if="!isLoggedIn" :location-id="locationId" @cancelled="handleAuthCancelled" />
  <PopupSelectEmployee v-if="isLoggedIn && selectingEmployee" @cancelled="handleSelectEmployeeCancelled" @employee-chosen-next-click="handleSelectEmployeeCancelled" />
  <div class="flex flex-col gap-5 px-5">
    <h2 class="my-5 flex justify-center text-center">
      <TextHeader1 class="text-center">{{ t('dateTime.header') }}</TextHeader1>
    </h2>
    <div v-if="fetchPaymentAccountsFailure || reserveBookingFailure" ref="pageErrorSection" class="flex justify-center">
      <div v-if="reserveBookingFailure" class="max-w-[600px]">
        <SectionError :error-message="t('dateTime.reserveBookingError')"/>
      </div>
      <div v-else class="max-w-[600px]">
        <SectionError :error-message="t('dateTime.paymentDetailsError')"/>
      </div>
    </div>
    <div v-if="!hasMultipleGuests && !hasParallelService" class="flex justify-center md:mb-10">
      <ButtonInScreenTertiary class="w-full max-w-[600px]" @click="handleSelectEmployeeClicked">
        <div class="flex gap-x-5 justify-between items-center">
          <Icon :icon="faUser" size="1x" />
          <TextHeader3 class="w-full text-left">
            {{ employeeChosen ? t('dateTime.nailArtist.chosen', { name: employeeChosen.firstName }) : t('dateTime.nailArtist.any') }}
          </TextHeader3>
          <Icon :icon="faAngleRight" size="lg" />
        </div>
      </ButtonInScreenTertiary>
    </div>
    <div class="flex flex-col md:flex-row gap-10">
      <Calendar class="w-full" v-model="bookingDateUtc" :min-date="minDate" :max-date="maxDate" />
      <div class="w-full flex flex-col gap-5 md:border-available-times-border md:border md:p-5">
        <h3 class="flex justify-center">
          <TextHeader2>{{ t('dateTime.allTimes') }}</TextHeader2>
        </h3>
        <div v-if="availableDateTimesUtc && availableDateTimesUtc.length > 0" role="radiogroup" class="w-full flex flex-wrap gap-5">
          <div v-for="availableDateTimeUtc in availableDateTimesUtc" class="w-[calc(33.333%-0.833rem)] lg:w-[calc(20%-1rem)] shrink-0 grow-0">
            <button
            :class="`block w-full p-5 ${bookingDateTimeUtc?.equals(availableDateTimeUtc) ? 'bg-button-grid-selected' : 'bg-button-grid-unselected'}`"
            @click="onTimeChosen(availableDateTimeUtc)"
            role="radio"
            >
              <TextBody1>
                {{ availableDateTimeUtc.toLocal().toFormat(t('dateTime.timeFormat')) }}
              </TextBody1>
            </button>
          </div>
        </div>
        <div v-else-if="availableDateTimesUtc && availableDateTimesUtc.length === 0" class="w-full h-full flex items-center justify-center">
            <TextBody1>{{ t('dateTime.noTimesLeft') }}</TextBody1>
        </div>
        <div v-else-if="fetchAvailableTimesFailure" class="w-full h-full flex justify-center">
          <SectionError :error-message="t('dateTime.availableTimesError')" :try-again-handler="handleAvailableTimesTryAgain"/>
        </div>
        <div v-else class="w-full h-full flex flex-col gap-4 items-center justify-center">
          <Icon :icon="faCircleNotch" size="2x" class="animate-spin"/>
          <TextBody1>{{t('dateTime.loading')}}</TextBody1>
        </div>
      </div>
    </div>
  </div>
</template>
