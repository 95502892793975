<script setup lang="ts">
import ButtonInScreenPrimary from "@/src/components/ButtonInScreenPrimary.vue";
import ButtonInScreenPrimarySkeleton from "@/src/components/ButtonInScreenPrimarySkeleton.vue";
import ButtonPrimary from "@/src/components/ButtonPrimary.vue";
import ClosePopup from "@/src/components/ClosePopup.vue";
import Icon from "@/src/components/Icon.vue";
import SectionDivider from "@/src/components/SectionDivider.vue";
import SectionError from "@/src/components/SectionError.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import type { Employee, LocationId } from "@/src/lib/townhouseApiClient";
import { useAuthStore } from "@/src/stores/authStore";
import { useBookingStore } from "@/src/stores/bookingStore";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const authStore = useAuthStore();
const bookingStore = useBookingStore();

const { employeeChosen, availableEmployees, fetchAvailableEmployeesFailure } = storeToRefs(bookingStore);

const chosenEmployee = ref<Employee | null>(employeeChosen.value);

const emit = defineEmits<{
  employeeChosenNextClick: [];
  cancelled: [];
}>();

onMounted(() => {
  authStore.analytics.track("Employee Select Shown", {
    locationId: bookingStore.locationId as LocationId,
    isPrBooking: bookingStore.isPrBooking,
    numberOfGuests: bookingStore.servicesByGuest.length,
  });
});

const handleEmployeeSelected = (employee: Employee | null) => {
  chosenEmployee.value = employee;
};

const handleAvailableEmployeesTryAgain = () => {
  bookingStore.fetchAvailableEmployees();
};

const handleEmployeeChosenNextClick = () => {
  // We do not await this promise so that the popup doesn't sit stalling whilst the available times are refetched
  // The loading handling on the date/times screen takes care of giving the user feedback that stuff is loading already
  bookingStore.setChosenEmployee(chosenEmployee.value);
  emit("employeeChosenNextClick");
};
</script>
<template>
  <div role="dialog" aria-modal="true" @click="$emit('cancelled')" class="fixed inset-0 z-20 w-screen h-screen bg-popup-background p-10 flex justify-center items-start">
    <div class="w-[600px] max-h-[calc(100vh-2.5rem)] overflow-auto bg-white relative">
      <div class="w-full flex flex-col justify-center items-center">
        <ClosePopup @click="$emit('cancelled')"/>
        <TextHeader1 class="p-10 px-12 text-center">{{ t("popup.selectEmployee.header") }}</TextHeader1>
        <SectionDivider />
        <div v-if="availableEmployees !== null"  class="p-10 w-full flex flex-col items-center">
          <div class="flex flex-col justify-between w-full max-w-[600px]">
            <ButtonInScreenPrimary :selected="chosenEmployee === null" @click="() => handleEmployeeSelected(null)" class="flex justify-start items-center w-full min-w-[250px] max-w-[600px] mb-5">
              <Icon class="mr-5" :icon="faUser" size="lg"/>
              <TextBody1>{{ t('popup.selectEmployee.any') }}</TextBody1>
            </ButtonInScreenPrimary>
            <ButtonInScreenPrimary v-for="availableEmployee in availableEmployees" :selected="availableEmployee.zenotiEmployeeId === chosenEmployee?.zenotiEmployeeId" @click="() => handleEmployeeSelected(availableEmployee)" class="flex justify-start items-center w-full min-w-[250px] max-w-[600px] mb-5">
              <Icon class="mr-5" :icon="faUser" size="lg"/>
              <TextBody1>{{ availableEmployee.firstName }}</TextBody1>
            </ButtonInScreenPrimary>
          </div>
          <ButtonPrimary @click="handleEmployeeChosenNextClick" class="basis-0 grow-0 max-w-[250px] mt-10 whitespace-nowrap">
            {{ t("popup.selectEmployee.next") }}
          </ButtonPrimary>
        </div>
        <div v-else-if="fetchAvailableEmployeesFailure" class="flex flex-col items-center">
          <SectionError :error-message="t('popup.selectEmployee.availableEmployeesError')" :try-again-handler="handleAvailableEmployeesTryAgain"/>
        </div>
        <div v-else class="p-10 flex flex-col items-center w-full">
          <div class="flex flex-col justify-between w-full">
            <ButtonInScreenPrimary disabled class="flex justify-start items-center w-full max-w-[600px] mb-5">
              <Icon class="mr-5" :icon="faUser" size="lg"/>
              <TextBody1>{{ t('popup.selectEmployee.any') }}</TextBody1>
            </ButtonInScreenPrimary>
            <ButtonInScreenPrimarySkeleton class="mb-5"/>
            <ButtonInScreenPrimarySkeleton class="mb-5"/>
          </div>
          <ButtonPrimary disabled  class="basis-0 grow-0 max-w-[250px] mt-10 whitespace-nowrap">
            {{ t("popup.selectEmployee.next") }}
          </ButtonPrimary>
        </div>
      </div>
    </div>
  </div>
</template>
