export const progressBookingStages = ["location", "guests", "services", "dateTime", "confirm", "complete"] as const;
export const allBookingStages = [
  "account",
  "reschedule",
  "location",
  "numberOfGuests",
  "groupPackages",
  "guests",
  "services",
  "addOns",
  "needsRemoval",
  "removals",
  "dateTime",
  "confirm",
  "complete",
] as const;

export type BookingProgressStage = (typeof progressBookingStages)[number];
export type BookingStage = (typeof allBookingStages)[number];
