<script setup lang="ts">
import ButtonInScreenTertiary from "@/src/components/ButtonInScreenTertiary.vue";
import ClosePopup from "@/src/components/ClosePopup.vue";
import Icon from "@/src/components/Icon.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import TextHeader1Skeleton from "@/src/components/TextHeader1Skeleton.vue";
import { useAuthStore } from "@/src/stores/authStore";
import { useUserStore } from "@/src/stores/userStore";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();
const userStore = useUserStore();
const { user, fetchUserFailure } = storeToRefs(userStore);

const headerText = computed(() => {
  if (!user.value) {
    return t("account.fallbackHeader");
  }

  const firstName = user.value.firstName;
  const possessive = firstName.endsWith("s") ? "'" : "'s";
  return t("account.header", { firstName, possessive });
});

const handleClosePopupClick = () => {
  if (route.query && typeof route.query["returnTo"] === "string") {
    return router.push(route.query["returnTo"]);
  }

  router.push("/");
};

const handleAccountClick = () => {
  router.push({ path: "/account/details", query: { returnTo: route.query["returnTo"] } });
};

const handleBookingsClick = () => {
  router.push({ path: "/account/bookings", query: { returnTo: route.query["returnTo"] } });
};

const handleRewardsClick = () => {
  router.push({ path: "/account/loyalty_points", query: { returnTo: route.query["returnTo"] } });
};

// FIXME: Hidden for SC testing, put back when it is ready and working
// const handleGiftCardsClick = () => {
//   return;
// };

const handlePaymentAccountsClick = () => {
  router.push({ path: "/account/payment_accounts", query: { returnTo: route.query["returnTo"] } });
};

const handleLogOutClick = () => {
  authStore.logout();
  return router.push("/");
};
</script>

<template>
  <div class="w-full max-w-[1140px] p-10 flex flex-col justify-center items-center gap-5 relative">
    <ClosePopup @click="handleClosePopupClick"/>
    <TextHeader1 v-if="user !== null" class="mb-10">{{ headerText }}</TextHeader1>
    <div v-else-if="!fetchUserFailure" class="mb-10 w-3/5 justify-center">
      <TextHeader1Skeleton />
    </div>
    <TextHeader1 v-else class="mb-10">{{ headerText }}</TextHeader1>
    <ButtonInScreenTertiary @click="handleAccountClick">
      <div class="flex justify-between items-center px-5">
        <TextBody1>{{ t("account.details.label") }}</TextBody1>
        <Icon :icon="faChevronRight" size="1x"></Icon>
      </div>
    </ButtonInScreenTertiary>
    <ButtonInScreenTertiary @click="handleBookingsClick">
      <div class="flex justify-between items-center px-5">
        <TextBody1>{{ t("account.bookings.label") }}</TextBody1>
        <Icon :icon="faChevronRight" size="1x"></Icon>
      </div>
    </ButtonInScreenTertiary>
    <ButtonInScreenTertiary @click="handleRewardsClick">
      <div class="flex justify-between items-center px-5">
        <TextBody1>{{ t("account.rewards.label") }}</TextBody1>
        <Icon :icon="faChevronRight" size="1x"></Icon>
      </div>
    </ButtonInScreenTertiary>
    <!-- FIXME: Hidden for SC testing, put back when it is ready and working -->
    <!-- <ButtonInScreenTertiary @click="handleGiftCardsClick">
      <div class="flex justify-between items-center px-5">
        <TextBody1>{{ t("account.giftCards.label") }}</TextBody1>
        <Icon :icon="faChevronRight" size="1x"></Icon>
      </div>
    </ButtonInScreenTertiary> -->
    <ButtonInScreenTertiary @click="handlePaymentAccountsClick">
      <div class="flex justify-between items-center px-5">
        <TextBody1>{{ t("account.paymentAccounts.label") }}</TextBody1>
        <Icon :icon="faChevronRight" size="1x"></Icon>
      </div>
    </ButtonInScreenTertiary>
    <ButtonInScreenTertiary @click="handleLogOutClick">
      <div class="flex justify-between items-center px-5">
        <TextBody1>{{ t("account.logOut.label") }}</TextBody1>
        <Icon :icon="faChevronRight" size="1x"></Icon>
      </div>
    </ButtonInScreenTertiary>
  </div>
</template>
