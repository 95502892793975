
<script setup lang="ts">
import GuestServiceSummarySkeleton from "@/src/components/GuestServiceSummarySkeleton.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextBody1Skeleton from "@/src/components/TextBody1Skeleton.vue";
import TextHeader3Skeleton from "@/src/components/TextHeader3Skeleton.vue";
import TextLink from "@/src/components/TextLink.vue";
import { useI18n } from "vue-i18n";

defineProps<{
  editMode?: boolean;
}>();

const { t } = useI18n();
</script>

<template>
 <div>
  <div class="mb-10">
    <span class="flex justify-between items-center">
      <TextHeader3Skeleton />
      <TextLink disabled v-if="editMode">
        <TextBody1>{{ t("bookingSummary.headers.edit") }}</TextBody1>
      </TextLink>
    </span>
    <TextBody1Skeleton class="block"/>
  </div>
  <div class="mb-10">
    <span class="flex justify-between items-center">
      <TextHeader3Skeleton />
      <TextLink disabled v-if="editMode" >
        <TextBody1>{{ t("bookingSummary.headers.edit") }}</TextBody1>
      </TextLink>
    </span>
    <TextBody1Skeleton />
  </div>
  <div class="mb-10">
    <span class="flex justify-between items-center">
      <TextHeader3Skeleton />
      <TextLink disabled v-if="editMode" >
        <TextBody1>{{ t("bookingSummary.headers.edit") }}</TextBody1>
      </TextLink>
    </span>
    <TextBody1Skeleton />
  </div>
  <div>
    <span class="flex justify-between items-center">
      <TextHeader3Skeleton />
      <TextLink disabled v-if="editMode" >
        <TextBody1>{{ t("bookingSummary.headers.edit") }}</TextBody1>
      </TextLink>
    </span>
    <GuestServiceSummarySkeleton />
  </div>
 </div>
</template>
