<script setup lang="ts">
import ButtonPlaintext from "@/src/components/ButtonPlaintext.vue";
import ButtonPrimary from "@/src/components/ButtonPrimary.vue";
import Checkbox from "@/src/components/Checkbox.vue";
import InputDate from "@/src/components/InputDate.vue";
import InputEmail from "@/src/components/InputEmail.vue";
import InputPassword from "@/src/components/InputPassword.vue";
import InputText from "@/src/components/InputText.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextBodyError from "@/src/components/TextBodyError.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import TextHeader3 from "@/src/components/TextHeader3.vue";
import TextLink from "@/src/components/TextLink.vue";
import { regionFromOrigin } from "@/src/config/env";
import {
  validateAndFormatDateOfBirth,
  validateAndFormatPhone,
  validateEmail,
  validateName,
  validatePassword,
} from "@/src/lib/formValidationHelpers";
import type { Email, LocationId, Password } from "@/src/lib/townhouseApiClient";
import { useAuthStore } from "@/src/stores/authStore";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

// Setup localization and store
const { t } = useI18n();
const authStore = useAuthStore();

const { isCreatingAccount } = storeToRefs(authStore);

// Props
const props = defineProps<{
  locationId: LocationId | null;
}>();

// Emits
const emits = defineEmits<{
  clickedLogin: [];
  enteredDetails: [boolean];
}>();

// State variables
const firstName = ref<string>("");
const lastName = ref<string>("");
const email = ref<string>("");
const phone = ref<string>("");
const dateOfBirthDay = ref<string>("");
const dateOfBirthMonth = ref<string>("");
const dateOfBirthYear = ref<string>("");
const password = ref<string>("");
const marketingEmailOptIn = ref(false);
const marketingSmsOptIn = ref(false);

const hasEnteredDetails = computed<boolean>(() =>
  Boolean(
    firstName.value ||
      lastName.value ||
      email.value ||
      phone.value ||
      dateOfBirthDay.value ||
      dateOfBirthMonth.value ||
      dateOfBirthYear.value ||
      password.value ||
      marketingEmailOptIn.value ||
      marketingSmsOptIn.value,
  ),
);

watch(hasEnteredDetails, (newValue) => {
  emits("enteredDetails", newValue);
});

// Error state variables
const formError = ref<string | undefined>();
const firstNameError = ref<string | undefined>();
const lastNameError = ref<string | undefined>();
const emailError = ref<string | undefined>();
const phoneError = ref<string | undefined>();
const dateOfBirthError = ref<string | undefined>();
const dateOfBirthErrorInDay = ref(false);
const dateOfBirthErrorInMonth = ref(false);
const dateOfBirthErrorInYear = ref(false);
const passwordError = ref<string | undefined>();

const currentRegion = regionFromOrigin();

// Create account function
const createAccount = async () => {
  formError.value = undefined;
  firstNameError.value = undefined;
  lastNameError.value = undefined;
  emailError.value = undefined;
  phoneError.value = undefined;
  dateOfBirthError.value = undefined;
  dateOfBirthErrorInDay.value = false;
  dateOfBirthErrorInMonth.value = false;
  dateOfBirthErrorInYear.value = false;
  passwordError.value = undefined;

  // Field validations
  validateName({
    name: firstName.value,
    errorRef: firstNameError,
    errorMessage: t("createAccount.error.firstNameInvalid"),
  });
  validateName({
    name: lastName.value,
    errorRef: lastNameError,
    errorMessage: t("createAccount.error.lastNameInvalid"),
  });
  validateEmail({ email: email.value, errorRef: emailError, errorMessage: t("createAccount.error.emailInvalid") });
  const phoneParsed = validateAndFormatPhone({
    phoneNumber: phone.value,
    region: currentRegion,
    errorRef: phoneError,
    errorMessage: t("createAccount.error.phoneInvalid"),
  });
  const dateOfBirthParsed = validateAndFormatDateOfBirth({
    dateOfBirth: {
      day: dateOfBirthDay.value,
      month: dateOfBirthMonth.value,
      year: dateOfBirthYear.value,
    },
    errorRefs: {
      dateErrorRef: dateOfBirthError,
      errorInDayRef: dateOfBirthErrorInDay,
      errorInMonthRef: dateOfBirthErrorInMonth,
      errorInYearRef: dateOfBirthErrorInYear,
    },
    errorMessages: {
      invalidError: t("createAccount.error.dateOfBirthInvalid"),
      belowAgeError: t("createAccount.error.dateOfBirthUnder16"),
    },
  });
  validatePassword({
    password: password.value,
    errorRef: passwordError,
    errorMessage: t("createAccount.error.passwordInvalid"),
  });

  if (
    firstNameError.value ||
    lastNameError.value ||
    emailError.value ||
    phoneError.value ||
    !phoneParsed ||
    dateOfBirthError.value ||
    !dateOfBirthParsed ||
    passwordError.value
  ) {
    return;
  }

  // Attempt account creation
  try {
    await authStore.createAccount({
      locationId: props.locationId,
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value as Email,
      phone: phoneParsed.e164Number,
      dateOfBirthDateUtc: dateOfBirthParsed,
      addressPostalCode: null,
      password: password.value as Password,
      marketingEmailOptIn: marketingEmailOptIn.value,
      marketingSmsOptIn: marketingSmsOptIn.value,
      tags: null,
    });
  } catch {
    formError.value = t("createAccount.error.all");
  }
};
</script>

<template>
  <form novalidate @submit.prevent="createAccount" class="p-10 flex flex-col gap-5">
    <TextHeader1 class="text-center">{{ t('createAccount.header') }}</TextHeader1>
    <ButtonPlaintext :disabled="isCreatingAccount" class="mb-10" @click="emits('clickedLogin')">
      <TextHeader3>{{ t('createAccount.alreadyHaveAccount') }} <TextLink :disabled="isCreatingAccount" @click="emits('clickedLogin')">{{ t('createAccount.login') }}</TextLink></TextHeader3>
    </ButtonPlaintext>
    <TextBodyError v-if="formError" role="alert">
      <TextBody1>{{ formError }}</TextBody1>
    </TextBodyError>
    <InputText :disabled="isCreatingAccount" v-model="firstName" :label="t('createAccount.label.firstName')" required :error="firstNameError" />
    <InputText :disabled="isCreatingAccount" v-model="lastName" :label="t('createAccount.label.lastName')" required :error="lastNameError" />
    <InputEmail :disabled="isCreatingAccount" v-model="email" :label="t('createAccount.label.email')" required :error="emailError" />
    <InputText :disabled="isCreatingAccount" v-model="phone" :label="t('createAccount.label.phone')" required :error="phoneError" />
    <InputDate :disabled="isCreatingAccount" v-model:day="dateOfBirthDay"
        v-model:month="dateOfBirthMonth"
        v-model:year="dateOfBirthYear"
        is-date-of-birth
        :label="t('createAccount.label.dateOfBirth')"
        required
        :error="dateOfBirthError"
        :error-in-day="dateOfBirthErrorInDay"
        :error-in-month="dateOfBirthErrorInMonth"
        :error-in-year="dateOfBirthErrorInYear" />
    <InputPassword :disabled="isCreatingAccount" v-model="password" :label="t('createAccount.label.password')" required :placeholder="t('createAccount.placeholder.password')" :error="passwordError" autocomplete="current-password" />
    <TextBody1>{{ t('createAccount.label.marketingOptIns') }}</TextBody1>
    <div class="flex gap-10">
      <Checkbox :disabled="isCreatingAccount" v-model="marketingEmailOptIn" :has-error="false">
        <TextBody1>{{ t('createAccount.label.marketingEmailOptIn') }}</TextBody1>
      </Checkbox>
      <Checkbox :disabled="isCreatingAccount" v-model="marketingSmsOptIn" :has-error="false">
        <TextBody1>{{ t('createAccount.label.marketingSmsOptIn') }}</TextBody1>
      </Checkbox>
    </div>
    <ButtonPrimary :submitting="isCreatingAccount" button-type="submit" class="mt-10">
      {{ t('createAccount.submit') }}
    </ButtonPrimary>
  </form>
</template>
