<script setup lang="ts">
import type { ButtonHTMLAttributes } from "vue";

const { disabled, buttonType = "button" } = defineProps<{
  disabled?: boolean;
  buttonType?: ButtonHTMLAttributes["type"];
}>();

const emits = defineEmits<{
  click: [];
}>();

const handleClick = () => {
  if (!disabled) {
    emits("click");
  }
};
</script>

<template>
  <button @click.stop="handleClick" class="block w-full" :type="buttonType" :class="`${disabled ? 'text-button-disabled cursor-not-allowed' : ''}`">
    <slot />
  </button>
</template>
