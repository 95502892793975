<script setup lang="ts">
import ButtonInScreenPrimary from "@/src/components/ButtonInScreenPrimary.vue";
import ButtonInScreenPrimarySkeleton from "@/src/components/ButtonInScreenPrimarySkeleton.vue";
import ButtonInScreenTertiary from "@/src/components/ButtonInScreenTertiary.vue";
import Icon from "@/src/components/Icon.vue";
import NavigationTop from "@/src/components/NavigationTop.vue";
import SectionDivider from "@/src/components/SectionDivider.vue";
import SectionError from "@/src/components/SectionError.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import TextLink from "@/src/components/TextLink.vue";
import type { PaymentAccount } from "@/src/lib/townhouseApiClient";
import PopupAddPaymentAccount from "@/src/popups/PopupAddPaymentAccount.vue";
import PopupConfirm from "@/src/popups/PopupConfirm.vue";
import { useAuthStore } from "@/src/stores/authStore";
import { useBookingStore } from "@/src/stores/bookingStore";
import { faCcAmex, faCcMastercard, faCcVisa } from "@fortawesome/free-brands-svg-icons";
import { type IconDefinition, faAngleRight, faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const bookingStore = useBookingStore();
const authStore = useAuthStore();
const { paymentAccounts, fetchPaymentAccountsFailure, deletePaymentAccountFailure, addPaymentAccountUrl } =
  storeToRefs(bookingStore);

const addPaymentAccountError = ref<string | null>(null);
const deletePaymentAccountRequested = ref<PaymentAccount | null>(null);

const handleBack = () => {
  router.push({ path: "/account", query: { returnTo: route.query["returnTo"] } });
};

const handleAddPaymentAccountStart = async () => {
  authStore.analytics.track("Add Payment Account Shown", {});

  await bookingStore.startCreatePaymentAccount();
};

const handleAddPaymentAccountCancelled = () => {
  bookingStore.clearPaymentAccountUrl();
};

const handleAddPaymentAccountSuccess = async () => {
  bookingStore.clearPaymentAccountUrl();
  await bookingStore.refetchPaymentAccounts();

  authStore.analytics.track("Payment Account Added Successfully", {});
};

const handleAddPaymentAccountFailed = (reason: string) => {
  addPaymentAccountError.value = reason;
  // FIXME: Do something with it

  authStore.analytics.track("Payment Account Addition Failed", {});
};

const handleDeletePaymentAccount = (paymentAccount: PaymentAccount) => {
  deletePaymentAccountRequested.value = paymentAccount;
};

const handleDeletePaymentAccountCancelled = () => {
  deletePaymentAccountRequested.value = null;
};

const handleDeletePaymentAccountConfirmed = async () => {
  if (!deletePaymentAccountRequested.value) {
    return;
  }

  await bookingStore.deletePaymentAccount(deletePaymentAccountRequested.value.id);

  deletePaymentAccountRequested.value = null;

  await bookingStore.refetchPaymentAccounts();

  authStore.analytics.track("Payment Account Deleted Successfully", {});
};

const handleTryAgain = () => {
  window.location.reload();
};

const paymentCardIconByPaymentAccountCardType = (cardType: PaymentAccount["cardType"]): IconDefinition => {
  switch (cardType) {
    case "amex":
      return faCcAmex;
    case "visa":
      return faCcVisa;
    case "mastercard":
      return faCcMastercard;
    case "other":
      return faCreditCard;
  }
};
</script>

<template>
  <PopupAddPaymentAccount v-if="addPaymentAccountUrl"
    @cancelled="handleAddPaymentAccountCancelled"
    @success="handleAddPaymentAccountSuccess"
    @failed="handleAddPaymentAccountFailed"
    :add-card-url="addPaymentAccountUrl" />
  <PopupConfirm v-if="deletePaymentAccountRequested"
    :prompt="t('account.paymentAccounts.confirmDelete', { cardLast4: deletePaymentAccountRequested.lastFour })"
    :error="t('account.paymentAccounts.deleteError')"
    @cancelled="handleDeletePaymentAccountCancelled"
    @confirmed="handleDeletePaymentAccountConfirmed"
    />
  <div class="w-full max-w-[1140px] flex flex-col justify-center items-center relative">
    <TextHeader1 class="p-10">{{ t("account.paymentAccounts.label") }}</TextHeader1>
    <NavigationTop @back="handleBack" />
    <div v-if="paymentAccounts && paymentAccounts.length > 0" class="w-full p-10 flex flex-col items-center gap-5">
      <ButtonInScreenPrimary v-for="paymentAccount in paymentAccounts" class="w-full max-w-[600px]"  @click="handleDeletePaymentAccount(paymentAccount)">
        <div class="flex justify-between items-center gap-5">
          <div>
            <Icon class="mr-5" :icon="paymentCardIconByPaymentAccountCardType(paymentAccount.cardType)" size="lg"/>
            <TextBody1>{{ t("account.paymentAccounts.card", { cardLast4: paymentAccount.lastFour}) }}</TextBody1>
          </div>
          <TextLink>
            <TextBody1>{{ t("account.paymentAccounts.delete") }}</TextBody1>
          </TextLink>
        </div>
      </ButtonInScreenPrimary>
    </div>
    <div v-else-if="paymentAccounts && paymentAccounts.length === 0" class="w-full">
      <SectionDivider class="w-full my-10" />
      <div class="w-full p-10 flex flex-col items-center gap-5">
        <TextBody1>{{ t("account.paymentAccounts.none") }}</TextBody1>
      </div>
    </div>
    <div v-else-if="fetchPaymentAccountsFailure" class="max-w-[600px]">
      <SectionError :error-message="t('general.pageError.error')" :try-again-handler="handleTryAgain" />  
    </div>
    <div v-else-if="deletePaymentAccountFailure" class="max-w-[600px]">
      <SectionError :error-message="t('general.pageError.error')" :try-again-handler="handleTryAgain" />  
    </div>  
    <div v-else class="w-full p-10 flex flex-col items-center gap-5">
      <ButtonInScreenPrimarySkeleton class="max-w-[600px]" />
      <ButtonInScreenPrimarySkeleton class="max-w-[600px]"/>
      <ButtonInScreenPrimarySkeleton class="max-w-[600px]"/>
    </div>
    <div v-if="!fetchPaymentAccountsFailure" class="w-full p-10 flex flex-col items-center">
      <ButtonInScreenTertiary :disabled="paymentAccounts === null" @click="handleAddPaymentAccountStart" class="w-full max-w-[600px]">
        <div class="flex justify-between items-center">
          <div>
            <Icon class="mr-5" :icon="faCreditCard" size="lg"/>
            <TextBody1>{{ t("confirm.payment.addCard") }}</TextBody1>
          </div>
          <Icon :icon="faAngleRight" size="lg"/>
        </div>
      </ButtonInScreenTertiary>
    </div>
  </div>
</template>
