<script setup lang="ts">
import ButtonPrimary from "@/src/components/ButtonPrimary.vue";
import ClosePopup from "@/src/components/ClosePopup.vue";
import InputTextArea from "@/src/components/InputTextArea.vue";
import SectionDivider from "@/src/components/SectionDivider.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import { useAuthStore } from "@/src/stores/authStore";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
const props = defineProps<{
  text: string | null;
}>();

const emit = defineEmits<{
  cancelled: [];
  save: [value: string | null];
}>();

const { t } = useI18n();

const authStore = useAuthStore();

onMounted(() => {
  authStore.analytics.track("Medical or Access Needs Shown", {});
});

const medicalAccessNeedsInfo = ref<string | null>(props.text);
const savedMedicalAccessNeedsInfo = ref<string | null>(props.text);
const errorMessage = ref<string | null>();
const handleClick = () => {
  // Error only if the user is trying to hit save and does not have any stored medical/access needs info
  if (
    !savedMedicalAccessNeedsInfo.value &&
    (!medicalAccessNeedsInfo.value || medicalAccessNeedsInfo.value.trim().length === 0)
  ) {
    errorMessage.value = t("popup.medicalOrAccessNotes.error");
    // Reset if there is any whitespace
    medicalAccessNeedsInfo.value = null;
    return;
  }

  emit("save", medicalAccessNeedsInfo.value || null);
  emit("cancelled");
  errorMessage.value = null;
};
</script>

<template>
  <div role="dialog" aria-modal="true" @click="$emit('cancelled')" class="fixed inset-0 z-20 w-screen h-screen bg-popup-background p-10 flex justify-center items-start">
    <div role="form" @click.stop class="w-[600px] max-h-[calc(100vh-2.5rem)] overflow-auto bg-white relative">
      <ClosePopup @click="$emit('cancelled')" />
      <div class="w-full flex flex-col justify-center items-center">
        <TextHeader1 class="p-10 text-center">{{ t("popup.medicalOrAccessNotes.header") }}</TextHeader1>
        <SectionDivider />
      </div>
      <div class="w-full flex flex-col gap-10 justify-center items-center p-10">
        <TextBody1>{{ t("popup.medicalOrAccessNotes.description") }}</TextBody1>
        <InputTextArea :error="errorMessage ?? undefined"  class="break-words" :label="t('popup.medicalOrAccessNotes.label')" v-model="medicalAccessNeedsInfo" :rows="5" :required="true"/>
        <div class="w-[300px] mt-10">
          <ButtonPrimary @click="handleClick">{{ t("popup.medicalOrAccessNotes.save") }}</ButtonPrimary>
        </div>
      </div>
    </div>
  </div>
</template>
