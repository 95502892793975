import { regionFromOrigin } from "@/src/config/env";
import type { Branded } from "@/src/lib/branded";
import { PhoneNumber } from "@/src/lib/phoneNumber";
// biome-ignore lint/style/noNamespaceImport: Sentry has to be imported like this
import * as Sentry from "@sentry/vue";
import _ from "lodash";
import { DateTime } from "luxon";
import { type ZodIssue, z } from "zod";

// Errors
export class TownhouseApiError<T = undefined> extends Error {
  constructor(message: string, context?: T, cause?: unknown) {
    super(undefined, { cause });
    this.name = this.constructor.name;
    this.message = `${message} ${JSON.stringify(context)}`;
  }
}

// Error when a 2xx response fails parsing
export class TownhouseApiSuccessResponseParseError extends TownhouseApiError<ZodIssue[]> {}
// Error when a 3xx-5xx problem json response fails parsing
export class TownhouseApiProblemJsonResponseParseError extends TownhouseApiError<ZodIssue[]> {}
export class TownhouseApiNonJsonResponseParseError extends TownhouseApiError<unknown> {}
export class TownhouseApiUnauthenticatedError extends TownhouseApiError {}
export class TownhouseApiResetPasswordInvalidVerificationCodeError extends TownhouseApiError {}
export class TownhouseApiResetPasswordAccountLockedError extends TownhouseApiError {}
export class TownhouseApiResetPasswordFailedError extends TownhouseApiError {}

// Branded types
export type AddOnId = Branded<string, "AddOnId">;
export type AddOnName = Branded<string, "AddOnName">;
export type AppointmentId = Branded<string, "AppointmentId">;
export type AppointmentItemId = Branded<string, "AppointmentItemId">;
export type AuthJwt = Branded<string, "AuthJwt">;
export type BookingId = Branded<string, "BookingId">;
export type Email = Branded<string, "Email">;
export type LocationId = Branded<string, "LocationId">;
export type LocationName = Branded<string, "LocationName">;
export type LocationAddressOne = Branded<string, "LocationAddressOne">;
export type LocationAddressTwo = Branded<string, "LocationAddressTwo">;
export type LocationCity = Branded<string, "LocationCity">;
export type LocationState = Branded<string, "LocationState">;
export type LocationPostalCode = Branded<string, "LocationPostalCode">;
export type Password = Branded<string, "Password">;
export type RemovalId = Branded<string, "RemovalId">;
export type RemovalName = Branded<string, "RemovalName">;
export type ServiceId = Branded<string, "ServiceId">;
export type ServiceName = Branded<string, "ServiceName">;
export type UserId = Branded<string, "UserId">;
export type BookingReservationId = Branded<string, "BookingReservationId">;
export type ZenotiEmployeeId = Branded<string, "ZenotiEmployeeId">;
export type ZenotiGuestId = Branded<string, "ZenotiGuestId">;
export type ZenotiPaymentAccountId = Branded<string, "ZenotiPaymentAccountId">;
export type ZenotiVerificationId = Branded<string, "ZenotiVerificationId">;
export type ZenotiVerificationCode = Branded<string, "ZenotiVerificationCode">;
export type MedicalOrAccessNotesInfo = Branded<string, "MedicalOrAccessNotesInfo">;

// Request types
export type CreateSessionFromZenotiLoginRequest = {
  email: Email;
  password: Password;
};

export type CreateBookingRequest = {
  locationId: LocationId;
  bookingDateTimeUtc: DateTime;
  zenotiEmployeeId: ZenotiEmployeeId | null;
  // Outer array is one per guest
  servicesByGuest: Array<
    // Inner array is one per service for the given guest
    Array<{
      serviceId: ServiceId;
      addOnIds: AddOnId[];
      removalIds: RemovalId[];
      inParallelWith: ServiceId | null;
    }>
  >;
  isPrBooking: boolean;
};

export type CreateBookingConfirmationRequest = {
  medicalOrAccessNotes: MedicalOrAccessNotesInfo | null;
};

export type CreateBookingRescheduleRequest = {
  userId: UserId;
  bookingId: BookingId;
  rescheduledDateTimeUtc: DateTime;
};

export type CreateBookingConfirmRescheduleRequest = {
  userId: UserId;
  bookingId: BookingId;
  rescheduledDateTimeUtc: DateTime;
};

export type CancelBookingRequest = {
  userId: UserId;
  bookingId: BookingId;
};

export type CreateUserRequest = {
  email: Email;
  firstName: string;
  lastName: string;
  locationId: LocationId | null;
  phone: string;
  dateOfBirthDateUtc: DateTime | null;
  password: Password;
  addressPostalCode: string | null;
  marketingEmailOptIn: boolean;
  marketingSmsOptIn: boolean;
  tags: string[] | null;
};

export type UpdateUserRequest = {
  firstName?: string;
  lastName?: string;
  phone?: PhoneNumber;
  dateOfBirthDateUtc?: DateTime;
  marketingEmailOptIn?: boolean;
  marketingSmsOptIn?: boolean;
};

// Response schemas
const sessionGetCurrentResponseSchema = z.object({
  sub: z.string().transform((s) => s as UserId),
  sessionType: z.literal("user"),
});

const sessionCreateFromZenotiLoginResponseSchema = z.object({
  userId: z.string().transform((s) => s as UserId),
  token: z.string().transform((s) => s as AuthJwt),
});

export const locationGetInfoResponseSchema = z.object({
  id: z.string().transform((s) => s as LocationId),
  name: z.string().transform((s) => s as LocationName),
  addressLineOne: z.string().transform((s) => s as LocationAddressOne),
  addressLineTwo: z
    .string()
    .transform((s) => s as LocationAddressTwo)
    .nullable(),
  city: z.string().transform((s) => s as LocationCity),
  state: z
    .string()
    .transform((s) => s as LocationState)
    .nullable(),
  postalCode: z.string().transform((s) => s as LocationPostalCode),
  phoneNumber: z.string().nullable(),
});

const serviceTypeSchema = z.enum([
  "manicure",
  "pedicure",
  "maniPediManicure",
  "maniPediPedicure",
  "groupPackage",
  "other",
]);

const addOnSchema = z.object({
  id: z.string().transform((s) => s as AddOnId),
  name: z.string().transform((s) => s as AddOnName),
  longDescription: z.string(),
  shortDescription: z.string(),
  typicalDurationMins: z.number(),
  priceCents: z.number(),
});

const removalSchema = z.object({
  id: z.string().transform((s) => s as RemovalId),
  name: z.string().transform((s) => s as RemovalName),
  longDescription: z.string(),
  shortDescription: z.string(),
  typicalDurationMins: z.number(),
  priceCents: z.number(),
});

export const servicesGetByLocationResponseItemSchema = z.object({
  id: z.string().transform((s) => s as ServiceId),
  name: z.string().transform((s) => s as ServiceName),
  serviceType: serviceTypeSchema,
  longDescription: z.string(),
  shortDescription: z.string(),
  typicalDurationMins: z.number(),
  parallelGroups: z.array(z.number()),
  priceCents: z.number(),
  addOns: z.array(addOnSchema).transform((addOns) => {
    // Convert to ES6 Map so that lookup is O(1) but order is preserved
    return addOns.reduce((acc, addOn) => acc.set(addOn.id, addOn), new Map<AddOnId, (typeof addOns)[number]>());
  }),
  removals: z.array(removalSchema).transform((removals) => {
    // Convert to ES6 Map so that lookup is O(1) but order is preserved
    return removals.reduce(
      (acc, removal) => acc.set(removal.id, removal),
      new Map<RemovalId, (typeof removals)[number]>(),
    );
  }),
});

export const servicesGetByLocationResponseSchema = z
  .array(servicesGetByLocationResponseItemSchema)
  .transform((services) => {
    // Convert to ES6 Map so that lookup is O(1) but order is preserved
    return services.reduce(
      (acc, service) => acc.set(service.id, service),
      new Map<ServiceId, (typeof services)[number]>(),
    );
  });

export const bookingGetAvailableEmployeesResponseSchema = z.object({
  employees: z.array(
    z.object({
      firstName: z.string(),
      zenotiEmployeeId: z
        .string()
        .uuid()
        .transform((s) => s as ZenotiEmployeeId),
    }),
  ),
});

const bookingGetAvailableTimesResponseSchema = z.array(
  z
    .string()
    .datetime()
    .transform((s) => DateTime.fromISO(s, { zone: "UTC" })),
);

const bookingCreateReservationResponseSchema = z.object({
  bookingReservationId: z.string().transform((s) => s as BookingReservationId),
  expiryDateTimeUtc: z
    .string()
    .datetime()
    .transform((s) => DateTime.fromISO(s, { zone: "UTC" })),
});

const bookingCreateRescheduleResponseSchema = z.object({
  availableDateTimeUtcs: z.array(
    z
      .string()
      .datetime()
      .transform((s) => DateTime.fromISO(s, { zone: "UTC" })),
  ),
});

const bookingCreateConfirmRescheduleResponseSchema = z.object({
  success: z.literal(true),
});

const bookingCancelResponseSchema = z.object({
  success: z.literal(true),
});

const userCreateResponseSchema = z.object({
  id: z.string().transform((s) => s as UserId),
  token: z.string().transform((s) => s as AuthJwt),
});

const paymentAccountSchema = z.object({
  id: z.string().transform((s) => s as ZenotiPaymentAccountId),
  cardType: z.enum(["amex", "visa", "mastercard", "other"]),
  lastFour: z.string().length(4),
  expiryDateTimeUtc: z
    .string()
    .datetime()
    .transform((s) => DateTime.fromISO(s, { zone: "UTC" })),
});

const userGetResponseSchema = z.object({
  id: z.string().transform((s) => s as UserId),
  email: z.string().transform((s) => s as Email),
  firstName: z.string(),
  lastName: z.string(),
  marketingEmailOptIn: z.boolean(),
  marketingSmsOptIn: z.boolean(),
  dateOfBirthUtc: z
    .string()
    .date()
    .transform((s) => DateTime.fromISO(s, { zone: "UTC" })),
  phoneNumber: z
    .string()
    .transform((n) => {
      try {
        return new PhoneNumber(n, regionFromOrigin());
      } catch (_) {
        return null;
      }
    })
    .nullable(),
});

const userGetFutureBookingsResponseSchema = z.array(
  z.object({
    id: z.string().transform((s) => s as BookingId),
    locationId: z.string().transform((s) => s as LocationId),
    startDateTimeUtc: z
      .string()
      .datetime()
      .transform((s) => DateTime.fromISO(s, { zone: "UTC" })),
    endDateTimeUtc: z
      .string()
      .datetime()
      .transform((s) => DateTime.fromISO(s, { zone: "UTC" })),
    appointments: z.array(
      z.object({
        id: z.string().transform((s) => s as AppointmentId),
        startDateTimeUtc: z
          .string()
          .datetime()
          .transform((s) => DateTime.fromISO(s, { zone: "UTC" })),
        endDateTimeUtc: z
          .string()
          .datetime()
          .transform((s) => DateTime.fromISO(s, { zone: "UTC" })),
        appointmentItems: z.array(
          z.object({
            id: z.string().transform((s) => s as AppointmentItemId),
            startDateTimeUtc: z
              .string()
              .datetime()
              .transform((s) => DateTime.fromISO(s, { zone: "UTC" })),
            endDateTimeUtc: z
              .string()
              .datetime()
              .transform((s) => DateTime.fromISO(s, { zone: "UTC" })),
            forServiceId: z
              .string()
              .transform((s) => s as ServiceId)
              .nullable(),
            inParallelWithServiceId: z
              .string()
              .transform((s) => s as ServiceId)
              .nullable(),
            service: z
              .object({
                id: z.string().transform((s) => s as ServiceId),
                name: z.string().transform((s) => s as ServiceName),
              })
              .nullable(),
            addOn: z
              .object({
                id: z.string().transform((s) => s as AddOnId),
                name: z.string().transform((s) => s as AddOnName),
              })
              .nullable(),
            removal: z
              .object({
                id: z.string().transform((s) => s as RemovalId),
                name: z.string().transform((s) => s as RemovalName),
              })
              .nullable(),
          }),
        ),
        user: z.object({
          id: z.string().transform((s) => s as UserId),
          guestNumber: z.number().nullable(),
          firstName: z.string(),
          lastName: z.string(),
        }),
      }),
    ),
    location: z.object({
      name: z.string().transform((s) => s as LocationName),
      addressLineOne: z.string().transform((s) => s as LocationAddressOne),
      addressLineTwo: z
        .string()
        .transform((s) => s as LocationAddressTwo)
        .nullable(),
      city: z.string().transform((s) => s as LocationCity),
      state: z
        .string()
        .transform((s) => s as LocationState)
        .nullable(),
      postalCode: z.string().transform((s) => s as LocationPostalCode),
    }),
    user: z.object({
      id: z.string().transform((s) => s as UserId),
      guestNumber: z.number().nullable(),
      firstName: z.string(),
      lastName: z.string(),
    }),
  }),
);

const userGetBookingResponseSchema = z.object({
  id: z.string().transform((s) => s as BookingId),
  locationId: z.string().transform((s) => s as LocationId),
  startDateTimeUtc: z
    .string()
    .datetime()
    .transform((s) => DateTime.fromISO(s, { zone: "UTC" })),
  endDateTimeUtc: z
    .string()
    .datetime()
    .transform((s) => DateTime.fromISO(s, { zone: "UTC" })),
  appointments: z.array(
    z.object({
      id: z.string().transform((s) => s as AppointmentId),
      startDateTimeUtc: z
        .string()
        .datetime()
        .transform((s) => DateTime.fromISO(s, { zone: "UTC" })),
      endDateTimeUtc: z
        .string()
        .datetime()
        .transform((s) => DateTime.fromISO(s, { zone: "UTC" })),
      appointmentItems: z.array(
        z.object({
          id: z.string().transform((s) => s as AppointmentItemId),
          startDateTimeUtc: z
            .string()
            .datetime()
            .transform((s) => DateTime.fromISO(s, { zone: "UTC" })),
          endDateTimeUtc: z
            .string()
            .datetime()
            .transform((s) => DateTime.fromISO(s, { zone: "UTC" })),
          forServiceId: z
            .string()
            .transform((s) => s as ServiceId)
            .nullable(),
          inParallelWithServiceId: z
            .string()
            .transform((s) => s as ServiceId)
            .nullable(),
          service: z
            .object({
              id: z.string().transform((s) => s as ServiceId),
              name: z.string().transform((s) => s as ServiceName),
            })
            .nullable(),
          addOn: z
            .object({
              id: z.string().transform((s) => s as AddOnId),
              name: z.string().transform((s) => s as AddOnName),
            })
            .nullable(),
          removal: z
            .object({
              id: z.string().transform((s) => s as RemovalId),
              name: z.string().transform((s) => s as RemovalName),
            })
            .nullable(),
        }),
      ),
      user: z.object({
        id: z.string().transform((s) => s as UserId),
        guestNumber: z.number().nullable(),
        firstName: z.string(),
        lastName: z.string(),
      }),
    }),
  ),
  location: z.object({
    name: z.string().transform((s) => s as LocationName),
    addressLineOne: z.string().transform((s) => s as LocationAddressOne),
    addressLineTwo: z
      .string()
      .transform((s) => s as LocationAddressTwo)
      .nullable(),
    city: z.string().transform((s) => s as LocationCity),
    state: z
      .string()
      .transform((s) => s as LocationState)
      .nullable(),
    postalCode: z.string().transform((s) => s as LocationPostalCode),
  }),
  user: z.object({
    id: z.string().transform((s) => s as UserId),
    guestNumber: z.number().nullable(),
    firstName: z.string(),
    lastName: z.string(),
  }),
});

const userGetLoyaltyPointsResponseSchema = z.object({
  pointsBalance: z.number(),
  pointsValueCents: z.number(),
});

export const userGetPaymentAccountsByLocationResponseSchema = z.array(paymentAccountSchema);

export const userCreatePaymentAccountByLocationResponseSchema = z.object({
  url: z
    .string()
    .url()
    .transform((s) => new URL(s)),
});

export const userDeletePaymentAccountSchema = z.object({
  success: z.literal(true),
});

export const userResetPasswordResponseSchema = z.object({
  verificationId: z
    .string()
    .uuid()
    .transform((s) => s as ZenotiVerificationId),
  guestId: z
    .string()
    .uuid()
    .transform((s) => s as ZenotiGuestId),
});

export const userResetPasswordCompleteResponseSchema = z.object({
  success: z.literal(true),
});

export const problemJsonResponseSchema = z
  .object({
    type: z.string(),
    status: z.union([
      z.literal(400),
      z.literal(401),
      z.literal(403),
      z.literal(404),
      z.literal(410),
      z.literal(422),
      z.literal(423),
      z.literal(500),
    ]),
    title: z.string(),
    detail: z.string(),
    instance: z.string().nullable(),
    context: z.any().optional(),
  })
  .strict();

export type ProblemJson = z.infer<typeof problemJsonResponseSchema>;

// Response types
export type AddOn = z.infer<typeof addOnSchema>;
export type Removal = z.infer<typeof removalSchema>;
export type ServiceType = z.infer<typeof serviceTypeSchema>;
export type SessionGetCurrentResponse = z.output<typeof sessionGetCurrentResponseSchema>;
export type SessionCreateFromZenotiLoginResponse = z.output<typeof sessionCreateFromZenotiLoginResponseSchema>;
export type LocationGetInfoResponse = z.output<typeof locationGetInfoResponseSchema>;
export type ServicesGetByLocationResponseItem = z.output<typeof servicesGetByLocationResponseItemSchema>;
export type ServicesGetByLocationResponse = z.output<typeof servicesGetByLocationResponseSchema>;
export type BookingGetAvailableEmployeesResponse = z.output<typeof bookingGetAvailableEmployeesResponseSchema>;
export type BookingGetAvailableTimesResponse = z.output<typeof bookingGetAvailableTimesResponseSchema>;
export type BookingCreateReservationResponse = z.output<typeof bookingCreateReservationResponseSchema>;
export type BookingCreateRescheduleResponse = z.output<typeof bookingCreateRescheduleResponseSchema>;
export type BookingCreateConfirmRescheduleResponse = z.output<typeof bookingCreateConfirmRescheduleResponseSchema>;
export type BookingCancelResponse = z.output<typeof bookingCancelResponseSchema>;
export type UserCreateResponse = z.infer<typeof userCreateResponseSchema>;
export type UserGetPaymentAccountsByLocationResponse = z.output<typeof userGetPaymentAccountsByLocationResponseSchema>;
export type PaymentAccount = z.output<typeof paymentAccountSchema>;
export type Employee = BookingGetAvailableEmployeesResponse["employees"][number];
export type UserCreatePaymentAccountByLocationResponse = z.output<
  typeof userCreatePaymentAccountByLocationResponseSchema
>;
export type UserResetPasswordResponse = z.output<typeof userResetPasswordResponseSchema>;
export type UserResetPasswordCompleteResponse = z.output<typeof userResetPasswordCompleteResponseSchema>;
export type UserGetResponse = z.output<typeof userGetResponseSchema>;
export type UserGetLoyaltyPointsResponse = z.output<typeof userGetLoyaltyPointsResponseSchema>;
export type UserGetFutureBookingsResponse = z.infer<typeof userGetFutureBookingsResponseSchema>;
export type UserGetBookingResponse = z.infer<typeof userGetBookingResponseSchema>;
export type BookingWithFullInfo = UserGetFutureBookingsResponse[number];

export class TownhouseApiClient {
  private apiOrigin: URL;
  private authJwt: AuthJwt | null;
  private fetchApi: typeof fetch | null;
  private fetchRetries: number;

  constructor(apiOrigin: URL, authJwt: AuthJwt | null = null, fetchApi: typeof fetch | null = null, fetchRetries = 1) {
    this.apiOrigin = apiOrigin;
    this.authJwt = authJwt;
    this.fetchApi = fetchApi;
    this.fetchRetries = fetchRetries;
  }

  setAuthJwt(authJwt: AuthJwt | null) {
    this.authJwt = authJwt;
  }

  async sessionGetCurrent(): Promise<SessionGetCurrentResponse> {
    if (!this.authJwt) {
      throw new TownhouseApiUnauthenticatedError("The 'sessionGetCurrent' API requires the user to be authenticated");
    }

    const body = await this.makeGetRequest("/v1/sessions/current");
    const result = sessionGetCurrentResponseSchema.safeParse(body);

    if (!result.success) {
      const error = new TownhouseApiSuccessResponseParseError(
        "Failed to parse JSON from '/v1/sessions/current' API call with context",
        result.error.issues,
      );

      Sentry.captureException(error);
      throw error;
    }

    return result.data;
  }

  async sessionCreateFromZenotiLogin(
    params: CreateSessionFromZenotiLoginRequest,
  ): Promise<SessionCreateFromZenotiLoginResponse> {
    const body = await this.makePostRequest("/v1/sessions/from_zenoti_login", JSON.stringify(params));
    const result = sessionCreateFromZenotiLoginResponseSchema.safeParse(body);

    if (!result.success) {
      const error = new TownhouseApiSuccessResponseParseError(
        "Failed to parse JSON from '/v1/sessions/from_zenoti_login' API call with context",
        result.error.issues,
      );

      Sentry.captureException(error);
      throw error;
    }

    return result.data;
  }

  async locationGetInfo(locationId: LocationId): Promise<LocationGetInfoResponse> {
    const body = await this.makeGetRequest(`/v1/locations/${locationId}/info`);
    const result = locationGetInfoResponseSchema.safeParse(body);

    if (!result.success) {
      const error = new TownhouseApiSuccessResponseParseError(
        `Failed to parse JSON from '/v1/locations/${locationId}/info' API call with context`,
        result.error.issues,
      );

      Sentry.captureException(error);
      throw error;
    }

    return result.data;
  }

  async servicesGetByLocation(locationId: LocationId): Promise<ServicesGetByLocationResponse> {
    const body = await this.makeGetRequest(`/v1/locations/${locationId}/services`);
    const result = servicesGetByLocationResponseSchema.safeParse(body);

    if (!result.success) {
      const error = new TownhouseApiSuccessResponseParseError(
        `Failed to parse JSON from '/v1/locations/${locationId}/services' API call with context`,
        result.error.issues,
      );

      Sentry.captureException(error);
      throw error;
    }

    return result.data;
  }

  async prServicesGetByLocation(locationId: LocationId): Promise<ServicesGetByLocationResponse> {
    const body = await this.makeGetRequest(`/v1/locations/${locationId}/services/pr`);
    const result = servicesGetByLocationResponseSchema.safeParse(body);

    if (!result.success) {
      const error = new TownhouseApiSuccessResponseParseError(
        `Failed to parse JSON from '/v1/locations/${locationId}/services/pr' API call with context`,
        result.error.issues,
      );

      Sentry.captureException(error);
      throw error;
    }

    return result.data;
  }

  async bookingGetAvailableEmployees(params: CreateBookingRequest): Promise<BookingGetAvailableEmployeesResponse> {
    if (!this.authJwt) {
      throw new TownhouseApiUnauthenticatedError(
        "The 'bookingGetAvailableEmployees' API requires the user to be authenticated",
      );
    }

    const body = await this.makePostRequest("/v1/bookings/available_employees", JSON.stringify(params));
    const result = bookingGetAvailableEmployeesResponseSchema.safeParse(body);

    if (!result.success) {
      const error = new TownhouseApiSuccessResponseParseError(
        "Failed to parse JSON from '/v1/bookings/available_employees' API call with context",
        result.error.issues,
      );

      Sentry.captureException(error);
      throw error;
    }

    return result.data;
  }

  async bookingGetAvailableTimes(params: CreateBookingRequest): Promise<BookingGetAvailableTimesResponse> {
    if (!this.authJwt) {
      throw new TownhouseApiUnauthenticatedError(
        "The 'bookingGetAvailableTimes' API requires the user to be authenticated",
      );
    }

    const body = await this.makePostRequest("/v1/bookings/available_times", JSON.stringify(params));
    const result = bookingGetAvailableTimesResponseSchema.safeParse(body);

    if (!result.success) {
      const error = new TownhouseApiSuccessResponseParseError(
        "Failed to parse JSON from '/v1/bookings/available_times' API call with context",
        result.error.issues,
      );

      Sentry.captureException(error);
      throw error;
    }

    return result.data;
  }

  async bookingCreateReservation(params: CreateBookingRequest): Promise<BookingCreateReservationResponse> {
    if (!this.authJwt) {
      throw new TownhouseApiUnauthenticatedError(
        "The 'bookingCreateReservation' API requires the user to be authenticated",
      );
    }

    const body = await this.makePostRequest("/v1/bookings/reserve", JSON.stringify(params));
    const result = bookingCreateReservationResponseSchema.safeParse(body);

    if (!result.success) {
      const error = new TownhouseApiSuccessResponseParseError(
        "Failed to parse JSON from '/v1/bookings/reserve' API call with context",
        result.error.issues,
      );

      Sentry.captureException(error);
      throw error;
    }

    return result.data;
  }

  async bookingCreateConfirmation(
    bookingReservationId: BookingReservationId,
    params: CreateBookingConfirmationRequest,
  ): Promise<void> {
    if (!this.authJwt) {
      throw new TownhouseApiUnauthenticatedError(
        "The 'bookingCreateConfirmation' API requires the user to be authenticated",
      );
    }

    await this.makePostRequest(`/v1/bookings/${bookingReservationId}/confirm`, JSON.stringify(params), false);
  }

  async bookingCreateReschedule(params: CreateBookingRescheduleRequest): Promise<BookingCreateRescheduleResponse> {
    if (!this.authJwt) {
      throw new TownhouseApiUnauthenticatedError(
        "The 'bookingCreateReschedule' API requires the user to be authenticated",
      );
    }

    const body = await this.makePostRequest(
      `/v1/users/${params.userId}/bookings/${params.bookingId}/reschedule`,
      JSON.stringify({
        rescheduledDateTimeUtc: params.rescheduledDateTimeUtc,
      }),
    );

    const result = bookingCreateRescheduleResponseSchema.safeParse(body);

    if (!result.success) {
      const error = new TownhouseApiSuccessResponseParseError(
        `Failed to parse JSON from '/v1/users/${params.userId}/bookings/${params.bookingId}/reschedule' API call with context`,
        result.error.issues,
      );

      Sentry.captureException(error);
      throw error;
    }

    return result.data;
  }

  async bookingCreateConfirmReschedule(
    params: CreateBookingConfirmRescheduleRequest,
  ): Promise<BookingCreateConfirmRescheduleResponse> {
    if (!this.authJwt) {
      throw new TownhouseApiUnauthenticatedError(
        "The 'bookingCreateConfirmReschedule' API requires the user to be authenticated",
      );
    }

    const body = await this.makePostRequest(
      `/v1/users/${params.userId}/bookings/${params.bookingId}/confirm_reschedule`,
      JSON.stringify({
        rescheduledDateTimeUtc: params.rescheduledDateTimeUtc,
      }),
    );
    const result = bookingCreateConfirmRescheduleResponseSchema.safeParse(body);

    if (!result.success) {
      const error = new TownhouseApiSuccessResponseParseError(
        `Failed to parse JSON from '/v1/users/${params.userId}/bookings/${params.bookingId}/confirm_reschedule' API call with context`,
        result.error.issues,
      );

      Sentry.captureException(error);
      throw error;
    }

    return result.data;
  }

  async cancelBooking(params: CancelBookingRequest): Promise<void> {
    if (!this.authJwt) {
      throw new TownhouseApiUnauthenticatedError("The 'cancelBooking' API requires the user to be authenticated");
    }

    const body = await this.makeDeleteRequest(`/v1/users/${params.userId}/bookings/${params.bookingId}/cancel`, null);
    const result = bookingCreateConfirmRescheduleResponseSchema.safeParse(body);

    if (!result.success) {
      const error = new TownhouseApiSuccessResponseParseError(
        `Failed to parse JSON from '/v1/users/${params.userId}/bookings/${params.bookingId}/cancel' API call with context`,
        result.error.issues,
      );

      Sentry.captureException(error);
      throw error;
    }
  }

  async userCreate(params: CreateUserRequest): Promise<UserCreateResponse> {
    const body = await this.makePostRequest(
      "/v1/users",
      JSON.stringify({
        ...params,
        dateOfBirthDateUtc: params.dateOfBirthDateUtc?.toISODate(),
      }),
    );
    const result = userCreateResponseSchema.safeParse(body);

    if (!result.success) {
      const error = new TownhouseApiSuccessResponseParseError(
        "Failed to parse JSON from '/v1/users' API call with context",
        result.error.issues,
      );

      Sentry.captureException(error);
      throw error;
    }

    return result.data;
  }

  async userGet(userId: UserId): Promise<UserGetResponse> {
    if (!this.authJwt) {
      throw new TownhouseApiUnauthenticatedError("The 'userGet' API requires the user to be authenticated");
    }

    const body = await this.makeGetRequest(`/v1/users/${userId}`);
    const result = userGetResponseSchema.safeParse(body);

    if (!result.success) {
      const error = new TownhouseApiSuccessResponseParseError(
        `Failed to parse JSON from '/v1/users/${userId}' API call with context`,
        result.error.issues,
      );

      Sentry.captureException(error);
      throw error;
    }

    return result.data;
  }

  async userGetFutureBookings(userId: UserId): Promise<UserGetFutureBookingsResponse> {
    if (!this.authJwt) {
      throw new TownhouseApiUnauthenticatedError(
        "The 'userGetFutureBookings' API requires the user to be authenticated",
      );
    }

    const body = await this.makeGetRequest(`/v1/users/${userId}/bookings/future`);
    const result = userGetFutureBookingsResponseSchema.safeParse(body);

    if (!result.success) {
      const error = new TownhouseApiSuccessResponseParseError(
        `Failed to parse JSON from '/v1/users/${userId}/bookings/future' API call with context`,
        result.error.issues,
      );

      Sentry.captureException(error);
      throw error;
    }

    return result.data;
  }

  async userGetBooking(userId: UserId, bookingId: BookingId): Promise<UserGetBookingResponse> {
    if (!this.authJwt) {
      throw new TownhouseApiUnauthenticatedError("The 'userGetBooking' API requires the user to be authenticated");
    }

    const body = await this.makeGetRequest(`/v1/users/${userId}/bookings/${bookingId}`);
    const result = userGetBookingResponseSchema.safeParse(body);

    if (!result.success) {
      const error = new TownhouseApiSuccessResponseParseError(
        `Failed to parse JSON from '/v1/users/${userId}/bookings/${bookingId}' API call with context`,
        result.error.issues,
      );

      Sentry.captureException(error);
      throw error;
    }

    return result.data;
  }

  async userGetLoyaltyPoints(userId: UserId): Promise<UserGetLoyaltyPointsResponse> {
    if (!this.authJwt) {
      throw new TownhouseApiUnauthenticatedError(
        "The 'userGetLoyaltyPoints' API requires the user to be authenticated",
      );
    }

    const body = await this.makeGetRequest(`/v1/users/${userId}/loyalty_points`);
    const result = userGetLoyaltyPointsResponseSchema.safeParse(body);

    if (!result.success) {
      const error = new TownhouseApiSuccessResponseParseError(
        `Failed to parse JSON from '/v1/users/${userId}/loyalty_points' API call with context`,
        result.error.issues,
      );

      Sentry.captureException(error);
      throw error;
    }

    return result.data;
  }

  async userUpdate(userId: UserId, params: UpdateUserRequest): Promise<void> {
    if (!this.authJwt) {
      throw new TownhouseApiUnauthenticatedError("The 'userUpdate' API requires the user to be authenticated");
    }

    await this.makePatchRequest(
      `/v1/users/${userId}`,
      JSON.stringify({
        ...params,
        phone: params.phone?.toString() || undefined,
        dateOfBirthDateUtc: params.dateOfBirthDateUtc?.toISODate() || undefined,
      }),
      false,
    );
  }

  async userGetPaymentAccounts(userId: UserId): Promise<UserGetPaymentAccountsByLocationResponse> {
    if (!this.authJwt) {
      throw new TownhouseApiUnauthenticatedError(
        "The 'userGetPaymentAccount' API requires the user to be authenticated",
      );
    }

    const body = await this.makeGetRequest(`/v1/users/${userId}/payment_accounts`);
    const result = userGetPaymentAccountsByLocationResponseSchema.safeParse(body);

    if (!result.success) {
      const error = new TownhouseApiSuccessResponseParseError(
        `Failed to parse JSON from '/v1/users/${userId}/payment_accounts' API call with context`,
        result.error.issues,
      );

      Sentry.captureException(error);
      throw error;
    }

    return result.data;
  }

  async userCreatePaymentAccount(userId: UserId): Promise<UserCreatePaymentAccountByLocationResponse> {
    if (!this.authJwt) {
      throw new TownhouseApiUnauthenticatedError(
        "The 'userCreatePaymentAccount' API requires the user to be authenticated",
      );
    }

    const body = await this.makePostRequest(`/v1/users/${userId}/payment_accounts`, null);
    const result = userCreatePaymentAccountByLocationResponseSchema.safeParse(body);

    if (!result.success) {
      const error = new TownhouseApiSuccessResponseParseError(
        `Failed to parse JSON from '/v1/users/${userId}/payment_accounts' API call with context`,
        result.error.issues,
      );

      Sentry.captureException(error);
      throw error;
    }

    return result.data;
  }

  async userDeletePaymentAccount(userId: UserId, paymentAccountId: ZenotiPaymentAccountId): Promise<void> {
    if (!this.authJwt) {
      throw new TownhouseApiUnauthenticatedError(
        "The 'userDeletePaymentAccount' API requires the user to be authenticated",
      );
    }

    const body = await this.makeDeleteRequest(`/v1/users/${userId}/payment_accounts/${paymentAccountId}`, null);
    const result = userDeletePaymentAccountSchema.safeParse(body);

    if (!result.success) {
      const error = new TownhouseApiSuccessResponseParseError(
        `Failed to parse JSON from '/v1/users/${userId}/payment_accounts/${paymentAccountId}' API call with context`,
        result.error.issues,
      );

      Sentry.captureException(error);
      throw error;
    }
  }

  async userResetPassword(email: Email): Promise<UserResetPasswordResponse> {
    const body = await this.makePostRequest(
      "/v1/users/reset_password",
      JSON.stringify({
        email,
      }),
    );
    const result = userResetPasswordResponseSchema.safeParse(body);

    if (!result.success) {
      const error = new TownhouseApiSuccessResponseParseError(
        "Failed to parse JSON from '/v1/users/reset_password' API call with context",
        result.error.issues,
      );

      Sentry.captureException(error);
      throw error;
    }

    return result.data;
  }

  async userCompleteResetPassword(
    guestId: ZenotiGuestId,
    verificationId: ZenotiVerificationId,
    verificationCode: ZenotiVerificationCode,
    newPassword: Password,
  ): Promise<void> {
    const body = await this.makePostRequest(
      "/v1/users/reset_password_complete",
      JSON.stringify({
        guestId,
        verificationId,
        verificationCode,
        password: newPassword,
      }),
      true,
      (problemJson: ProblemJson) => {
        if (problemJson.type === "urn:x-townhouse-api-error:zenoti-guest-password-invalid-verification-code-error") {
          throw new TownhouseApiResetPasswordInvalidVerificationCodeError(problemJson.detail);
        }

        if (problemJson.type === "urn:x-townhouse-api-error:zenoti-guest-password-account-locked-error") {
          throw new TownhouseApiResetPasswordAccountLockedError(problemJson.detail);
        }

        if (problemJson.type === "urn:x-townhouse-api-error:zenoti-guest-password-reset-failed-error") {
          throw new TownhouseApiResetPasswordFailedError(problemJson.detail);
        }

        return Promise.resolve();
      },
    );
    const result = userResetPasswordCompleteResponseSchema.safeParse(body);

    if (!result.success) {
      const error = new TownhouseApiSuccessResponseParseError(
        "Failed to parse JSON from '/v1/users/reset_password_complete' API call with context",
        result.error.issues,
      );

      Sentry.captureException(error);
      throw error;
    }
  }

  private async makeGetRequest(
    path: string,
    useResponseBody = true,
    customErrorHandler: (res: Response, problemJson: ProblemJson) => Promise<void> = () => Promise.resolve(),
  ): Promise<unknown> {
    const url = new URL(`${this.apiOrigin.origin}${path}`);
    const req = new Request(url, {
      headers: _.omitBy(
        {
          authorization: this.authJwt ? `bearer ${this.authJwt}` : undefined,
          accept: "application/json",
        },
        _.isNil,
      ) as HeadersInit,
      credentials: "include",
    });

    // Clone request so that we can reuse the request body
    let res = await (this.fetchApi || window.fetch)(req.clone());

    // We retry the fetch request n times if the result is a 5xx
    // otherwise if its a 2xx, 3xx or 4xx we continue parsing the response
    for (let i = 0; i < this.fetchRetries; i++) {
      if (res.ok || (res.status > 399 && res.status < 500)) {
        break;
      }

      // Clone request so that we can reuse the request body
      res = await (this.fetchApi || window.fetch)(req.clone());
    }

    let resBody: unknown;

    try {
      resBody = await res.json();
    } catch (e) {
      const error = new TownhouseApiNonJsonResponseParseError(
        `Failed to parse non-JSON error from '${url.pathname}' API call with context`,
        String(e),
        e,
      );
      Sentry.captureException(error);
      throw error;
    }

    if (!res.ok) {
      let problemJson: ProblemJson;

      const result = problemJsonResponseSchema.safeParse(resBody);

      if (!result.success) {
        const error = new TownhouseApiProblemJsonResponseParseError(
          `Failed to parse Problem JSON error response body from '${url.pathname}' API call with context`,
          result.error.issues,
        );

        Sentry.captureException(error);
        throw error;
      }

      problemJson = result.data;

      if (problemJson) {
        await customErrorHandler(res, problemJson);
      }

      throw new TownhouseApiError(`Failed to call '${url.pathname}', received Problem JSON error`, problemJson);
    }

    if (useResponseBody) {
      return resBody;
    }
  }

  private async makeRequestWithBody(
    path: string,
    method: "POST" | "PATCH" | "PUT" | "DELETE",
    body: BodyInit | null,
    useResponseBody = true,
    customErrorHandler: (problemJson: ProblemJson) => Promise<void> = () => Promise.resolve(),
  ): Promise<unknown> {
    const url = new URL(`${this.apiOrigin.origin}${path}`);
    const req = new Request(url, {
      method,
      headers: _.omitBy(
        {
          authorization: this.authJwt ? `bearer ${this.authJwt}` : undefined,
          accept: "application/json",
          "content-type": "application/json",
        },
        _.isNil,
      ) as HeadersInit,
      body,
      credentials: "include",
    });

    // Clone request so that we can reuse the request body for POST requests
    let res = await (this.fetchApi || window.fetch)(req.clone());

    // We retry the fetch request n times if the result is a 5xx
    // otherwise if its a 2xx, 3xx or 4xx we continue parsing the response
    for (let i = 0; i < this.fetchRetries; i++) {
      if (res.ok || (res.status > 399 && res.status < 500)) {
        break;
      }

      // Clone request so that we can reuse the request body for POST requests
      res = await (this.fetchApi || window.fetch)(req.clone());
    }

    let resBody: unknown;

    try {
      resBody = await res.json();
    } catch (e) {
      const error = new TownhouseApiNonJsonResponseParseError(
        `Failed to parse non-JSON error from '${url.pathname}' API call with context`,
        String(e),
        e,
      );
      Sentry.captureException(error);
      throw error;
    }

    if (!res.ok) {
      let problemJson: ProblemJson;

      const result = problemJsonResponseSchema.safeParse(resBody);

      if (!result.success) {
        const error = new TownhouseApiProblemJsonResponseParseError(
          `Failed to parse Problem JSON error response body from '${url.pathname}' API call with context`,
          result.error.issues,
        );

        Sentry.captureException(error);
        throw error;
      }

      problemJson = result.data;

      if (problemJson) {
        await customErrorHandler(problemJson);
      }

      throw new TownhouseApiError(`Failed to call '${url.pathname}', received Problem JSON error`, problemJson);
    }

    if (useResponseBody) {
      return resBody;
    }
  }

  private async makePostRequest(
    path: string,
    body: BodyInit | null,
    useResponseBody = true,
    customErrorHandler: (problemJson: ProblemJson) => Promise<void> = () => Promise.resolve(),
  ): Promise<unknown> {
    return await this.makeRequestWithBody(path, "POST", body, useResponseBody, customErrorHandler);
  }

  private async makePatchRequest(
    path: string,
    body: BodyInit | null,
    useResponseBody = true,
    customErrorHandler: (problemJson: ProblemJson) => Promise<void> = () => Promise.resolve(),
  ): Promise<unknown> {
    return await this.makeRequestWithBody(path, "PATCH", body, useResponseBody, customErrorHandler);
  }

  private async makeDeleteRequest(
    path: string,
    body: BodyInit | null,
    useResponseBody = true,
    customErrorHandler: (problemJson: ProblemJson) => Promise<void> = () => Promise.resolve(),
  ): Promise<unknown> {
    return await this.makeRequestWithBody(path, "DELETE", body, useResponseBody, customErrorHandler);
  }
}
