<script setup lang="ts">
import logo from "@/src/assets/logo.svg";
import Icon from "@/src/components/Icon.vue";
import { faCircleUser } from "@fortawesome/free-regular-svg-icons";
import { faBars, faCircleUser as faCircleUserSolid } from "@fortawesome/free-solid-svg-icons";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

defineProps<{
  accountFilled: boolean;
}>();

defineEmits<{
  mainMenu: [];
  account: [];
}>();
</script>

<template>
  <div class="flex justify-between items-center p-5">
    <div role="button" aria-haspopup="menu" @click="$emit('mainMenu')" class="cursor-pointer" >
      <Icon :icon=faBars size="3x" class="text-gray-300"></Icon>
    </div>
    <div class="w-[300px] grow-0 shrink-0">
      <a :href="`${t('general.mainWebsite')}`" >
        <img :src="logo" alt="Company Logo" />
      </a>
    </div>
    <div class="w-12">
      <div role="button" @click="$emit('account')" class="cursor-pointer">
        <Icon :icon="accountFilled ? faCircleUserSolid : faCircleUser" size="3x" class="text-gray-300"></Icon>
      </div>
    </div>
  </div>
</template>
