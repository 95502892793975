import type { AddOnName, BookingWithFullInfo, RemovalName, ServiceId } from "@/src/lib/townhouseApiClient";
import {
  type BookingState,
  BookingStoreNonExistentServiceError,
  type BookingSummary,
  type ServiceForGuest,
  type ServiceSummary,
  type SingleServiceSummary,
} from "@/src/stores/bookingStore";
import _ from "lodash";

export class BookingStoreMapNameError extends Error {}
export class BookingSummaryIncorrectParallelAppointmentItemError extends Error {}

export const mapToNames = <Id extends string, Name>(ids: Set<Id>, items: Map<Id, { name: Name }>): Name[] =>
  Array.from(ids).map((id) => {
    const info = items.get(id);
    if (!info) {
      throw new BookingStoreMapNameError(`Failed to find a name associated with item ID ${id}`);
    }
    return info.name;
  });

export const serviceToSummary = (
  servicesByLocation: NonNullable<BookingState["servicesByLocation"]>,
  serviceId: ServiceId,
  service: ServiceForGuest,
) => {
  const serviceInfo = servicesByLocation.get(serviceId);

  if (!serviceInfo) {
    throw new BookingStoreNonExistentServiceError(`Failed to find service by location with ID ${serviceId}`);
  }

  return {
    serviceName: serviceInfo.name,
    addOnNames: mapToNames(service.addOnIds, serviceInfo.addOns),
    removalNames: mapToNames(service.removalIds, serviceInfo.removals),
  };
};

export const bookingToBookingSummary = (booking: BookingWithFullInfo): BookingSummary => {
  const serviceSummariesByGuest: ServiceSummary[][] = booking.appointments.map((appointment) => {
    const addOnsByServiceId = _.groupBy(
      appointment.appointmentItems.filter((appointmentItem) => appointmentItem.addOn),
      (appointmentItem) => appointmentItem.forServiceId,
    );
    const removalsByServiceId = _.groupBy(
      appointment.appointmentItems.filter((appointmentItem) => appointmentItem.removal),
      (appointmentItem) => appointmentItem.forServiceId,
    );

    const serviceToServiceSummary = (
      service: NonNullable<(typeof appointment.appointmentItems)[number]["service"]>,
    ): SingleServiceSummary => {
      return {
        serviceName: service.name,
        addOnNames: addOnsByServiceId[service.id]?.map((addOn) => addOn.addOn?.name as AddOnName) || [], // We cast, it cannot be null but TS just can't see it
        removalNames: removalsByServiceId[service.id]?.map((removal) => removal.removal?.name as RemovalName) || [], // We cast, it cannot be null but TS just can't see it
      };
    };

    // We reduce into a Map to avoid duplicates to do with parallel services — see below
    const serviceSummaries = appointment.appointmentItems.reduce((acc, appointmentItem) => {
      if (!appointmentItem.service) {
        return acc;
      }

      if (appointmentItem.inParallelWithServiceId) {
        // return parallel service
        const otherService = appointment.appointmentItems.find(
          (otherAppointmentItem) => otherAppointmentItem.service?.id === appointmentItem.inParallelWithServiceId,
        );

        if (!otherService?.service) {
          throw new BookingSummaryIncorrectParallelAppointmentItemError(
            `Appointment Item with ID '${appointmentItem.id}' marked as in-parallel with service '${appointmentItem.inParallelWithServiceId}' which can't be found`,
          );
        }

        // We sort them consistently by ID so that the Map takes care of ensuring the mani-pedi appears only once
        // and not twice per time we find each half of the parallel service
        const [serviceId, ..._rest] = _.sortBy([appointmentItem.service.id, otherService.service.id]);

        acc.set(serviceId, {
          serviceOne: serviceToServiceSummary(appointmentItem.service),
          serviceTwo: serviceToServiceSummary(otherService.service),
        });

        return acc;
      }

      acc.set(appointmentItem.service.id, serviceToServiceSummary(appointmentItem.service));

      return acc;
    }, new Map<ServiceId, ServiceSummary>());

    return Array.from(serviceSummaries.values());
  });

  return {
    locationName: booking.location.name,
    addressOne: booking.location.addressLineOne,
    addressTwo: booking.location.addressLineTwo,
    city: booking.location.city,
    state: booking.location.state,
    postalCode: booking.location.postalCode,
    bookingDateTimeUtc: booking.startDateTimeUtc,
    serviceSummariesByGuest,
  };
};

export const bookingsToBookingSummaries = (bookings: BookingWithFullInfo[]): BookingSummary[] => {
  return bookings.map(bookingToBookingSummary);
};
