<script setup lang="ts">
import ServiceCardImage from "@/src/assets/service-card-placeholder.jpg";
import SectionError from "@/src/components/SectionError.vue";
import ServiceCard from "@/src/components/ServiceCard.vue";
import ServiceCardSkeleton from "@/src/components/ServiceCardSkeleton.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import TextHeader1Skeleton from "@/src/components/TextHeader1Skeleton.vue";
import type { AddOn, AddOnId, LocationId, ServiceId } from "@/src/lib/townhouseApiClient";
import PopupServiceInfo from "@/src/popups/PopupServiceInfo.vue";
import { type GuestNumber, useBookingStore } from "@/src/stores/bookingStore";
import _ from "lodash";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const bookingStore = useBookingStore();

const props = defineProps<{
  locationId: LocationId;
  guestNumber: GuestNumber;
  serviceId: ServiceId;
  press: boolean | undefined;
}>();

const { servicesByLocation, servicesByGuest, hasMultipleGuests, fetchServicesFailure } = storeToRefs(bookingStore);
const addOnForPopupServiceInfo = ref<Pick<
  AddOn,
  "name" | "longDescription" | "priceCents" | "typicalDurationMins"
> | null>(null);

const serviceType = computed(() => servicesByLocation.value?.get(props.serviceId)?.serviceType);
const addOns = computed(() => bookingStore.getAddOnsFromServiceId(props.serviceId));

const handleAddOnToggled = (addOnId: AddOnId, newValue: boolean) => {
  if (!newValue) {
    bookingStore.removeAddOnFromGuest(props.guestNumber, props.serviceId, addOnId);
    return;
  }

  bookingStore.addAddOnToGuest(props.guestNumber, props.serviceId, addOnId);
};

const guestHasAddOn = (addOnId: AddOnId): boolean => {
  return Boolean(servicesByGuest.value[props.guestNumber - 1]?.get(props.serviceId)?.addOnIds.has(addOnId));
};

const handleLearnMoreClicked = (addOn: AddOn) => {
  addOnForPopupServiceInfo.value = {
    name: addOn.name,
    longDescription: addOn.longDescription,
    priceCents: addOn.priceCents,
    typicalDurationMins: addOn.typicalDurationMins,
  };
};

const handleTryAgain = () => {
  window.location.reload();
};
</script>

<template>
  <h2 v-if="serviceType" class="flex flex-col justify-center my-5">
    <TextHeader1 v-if="hasMultipleGuests" class="text-center">{{ t('general.guestLabel', { guestNumber }) }}:</TextHeader1>
    <TextHeader1 class="text-center">{{ ['manicure', 'maniPediManicure'].includes(serviceType) ? t('addOns.headerManicure') : t('addOns.headerPedicure') }}</TextHeader1>
  </h2>
  <h2 v-else-if="!fetchServicesFailure" class="flex justify-center my-5">
    <div class="w-3/5 justify-center">
      <TextHeader1Skeleton />
    </div>
  </h2>
  <div v-if="servicesByLocation !== null" class="flex flex-col md:flex-row md:flex-wrap justify-between gap-5 px-5">
    <PopupServiceInfo v-if="addOnForPopupServiceInfo" @cancelled="addOnForPopupServiceInfo = null" :serviceName="addOnForPopupServiceInfo.name" :description="addOnForPopupServiceInfo.longDescription" :priceCents="addOnForPopupServiceInfo.priceCents" :durationMins="addOnForPopupServiceInfo.typicalDurationMins" />
    <div v-for="addOn in addOns" class="w-full md:w-[calc(50%-0.625rem)] shrink-0 grow-0">
      <ServiceCard @learnMore="() => handleLearnMoreClicked(addOn)" :modelValue="guestHasAddOn(addOn.id)"
        @update:modelValue="(newValue: boolean) => handleAddOnToggled(addOn.id, newValue)"
        :image="ServiceCardImage"
        :header="addOn.name"
        :bodyText="addOn.shortDescription"
        :duration="addOn.typicalDurationMins"
        :priceCents="addOn.priceCents" />
    </div>
  </div>
  <div v-else-if="fetchServicesFailure" class="w-full flex justify-center">
    <div class="max-w-[600px]">
      <SectionError :error-message="t('general.pageError.error')" :try-again-handler="handleTryAgain" />  
    </div>
  </div>
  <div v-else>
    <div class="flex flex-col md:flex-row md:flex-wrap justify-between gap-5 px-5">
      <div v-for="_ in _.times(10)" class="w-full md:w-[calc(50%-0.625rem)] shrink-0 grow-0">
        <ServiceCardSkeleton />
      </div>
    </div>
  </div>
</template>
